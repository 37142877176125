import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { Close } from "@material-ui/icons";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

import Header from "../components/Headers/Header";
import DateRange from "../components/DateRange/DateRange";
import DateRangePicker from "../components/DateRange/DateRangePicker";
import useScreenResolution from "../hooks/useScreenResolution";
import {
  getDashboardCount,
  getSalesReport,
} from "../store/actions/userActions";

const Index = () => {
  const dispatch = useDispatch();
  const { width } = useScreenResolution();
  const { token } = useSelector((state) => state.auth);
  const { salesReport, dashboardCount } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  const [state, setState] = useState({
    startDate: moment().startOf("week").valueOf(),
    endDate: moment().valueOf(),
    key: "selection",
    period: "thisMonth",
    label: "This Month",
    isCustom: false,
    isSelected: false,
  });

  const [chartData, setChartData] = useState({
    labels: [],
    sales: [],
    units: [],
  });

  useEffect(() => {
    const startDate = moment(state?.startDate);
    const endDate = moment(state?.endDate);
    if (state?.isCustom) {
      const totalDays = endDate.diff(startDate, "days");
      if (totalDays > 7) {
        state.period = "month";
      } else {
        state.period = "day";
      }

      setState(state);
    }
    dispatch(
      getSalesReport(
        {
          ...state,
          startDate: moment(state?.startDate).valueOf(),
          endDate: moment(state?.endDate).valueOf(),
        },
        token
      )
    );
  }, [token, state]);

  useEffect(() => {
    if (token) dispatch(getDashboardCount(token));
  }, [token]);

  useEffect(() => {
    const timePeriod = state?.period;
    let labels = [];
    let totalSales = [];
    let totalUnits = [];
    const currentDate = moment();

    if (state?.isCustom) {
      const startDate = moment(state?.startDate);
      const endDate = moment(state?.endDate);

      if (state.period === "day" || state.period === "month") {
        const totalDays = endDate.diff(startDate, "days");
        if (totalDays > 7) {
          const startOfDay = startDate.clone().startOf("days");
          labels = Array.from({ length: totalDays + 1 }, (_, i) =>
            startOfDay.clone().add(i, "days").format("D")
          );
          totalSales = Array(totalDays + 1).fill(0);
          totalUnits = Array(totalDays + 1).fill(0);
        } else {
          const startOfDay = startDate.clone().startOf("days");
          labels = Array.from({ length: totalDays + 1 }, (_, i) =>
            startOfDay.clone().add(i, "days").format("ddd")
          );
          totalSales = Array(totalDays + 1).fill(0);
          totalUnits = Array(totalDays + 1).fill(0);
        }
      }
    } else if (timePeriod === "today") {
      labels = [currentDate.format("ddd")];
      totalSales = [0];
      totalUnits = [0];
    } else if (timePeriod === "yesterday") {
      labels = [currentDate.clone().subtract(1, "day").format("ddd")];
      totalSales = [0];
      totalUnits = [0];
    } else if (
      timePeriod === "thisWeek" ||
      timePeriod === "lastWeek" ||
      timePeriod === "last7Days" ||
      timePeriod === "day"
    ) {
      const startOfWeek = currentDate.clone().startOf("week");
      labels = Array.from({ length: 7 }, (_, i) =>
        startOfWeek.clone().add(i, "days").format("ddd")
      );

      totalSales = Array(7).fill(0);
      totalUnits = Array(7).fill(0);
    } else if (timePeriod === "last14Days") {
      labels = Array.from({ length: 14 }, (_, i) =>
        currentDate
          .clone()
          .subtract(13 - i, "days")
          .format("D")
      );

      totalSales = Array(14).fill(0);
      totalUnits = Array(14).fill(0);
    } else if (
      timePeriod === "thisMonth" ||
      timePeriod === "lastMonth" ||
      timePeriod === "last30Days" ||
      timePeriod === "month"
    ) {
      const daysInMonth = currentDate.daysInMonth();
      labels = Array.from({ length: daysInMonth }, (_, i) =>
        currentDate
          .clone()
          .date(i + 1)
          .format("D")
      );
      totalSales = Array(daysInMonth).fill(0);
      totalUnits = Array(daysInMonth).fill(0);
    } else if (timePeriod === "month") {
      const month = [...new Set(salesReport.map((data) => data.month))].sort();
      labels = month.map((year) => String(year));
      totalSales = Array(month.length).fill(0);
      totalUnits = Array(month.length).fill(0);
    } else if (timePeriod === "allTime" || timePeriod === "year") {
      const years = [...new Set(salesReport.map((data) => data.year))].sort();
      labels = years.map((year) => String(year));
      totalSales = Array(years.length).fill(0);
      totalUnits = Array(years.length).fill(0);
    }

    salesReport.forEach((data) => {
      let index = -1;
      const dataDate = moment(data.createdAt);

      if (timePeriod === "today" || timePeriod === "yesterday") {
        index = labels.indexOf(data.day);
      } else if (
        timePeriod === "thisWeek" ||
        timePeriod === "lastWeek" ||
        timePeriod === "last7Days" ||
        timePeriod === "day"
      ) {
        if (data.day) {
          const index = labels.findIndex((ele) => ele == data.day);
          if (index !== -1) {
            totalSales[index] += data.totalSales;
            totalUnits[index] += data.totalUnits;
          }
        }
      } else if (
        timePeriod === "thisMonth" ||
        timePeriod === "lastMonth" ||
        timePeriod === "last14Days" ||
        timePeriod === "last30Days" ||
        timePeriod === "month"
      ) {
        if (data.dayOfMonth) {
          const index = labels.findIndex((ele) => ele == data?.dayOfMonth);
          if (index !== -1) {
            totalSales[index] += data.totalSales;
            totalUnits[index] += data.totalUnits;
          }
        } else {
          const index = labels.findIndex((ele) => ele == data?.month);
          if (index !== -1) {
            totalSales[index] += data.totalSales;
            totalUnits[index] += data.totalUnits;
          }
        }
      } else if (timePeriod === "allTime") {
        index = labels.indexOf(String(data.year));
      }

      if (index !== -1) {
        totalSales[index] += data.totalSales;
        totalUnits[index] += data.totalUnits;
      }
    });

    setChartData({
      labels,
      sales: totalSales,
      units: totalUnits,
    });
  }, [JSON.stringify(salesReport), state?.period]);

  const series = [
    {
      name: "Total Sales ($)",
      data: chartData?.sales,
    },
    {
      name: "Units Sold",
      data: chartData?.units,
    },
  ];

  const getOptions = (categories) => {
    return {
      legend: {
        show: false,
      },
      grid: {},
      chart: {
        type: "area",
        height: 350,
        toolbar: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories,
      },
      yaxis: [
        {},
        {
          opposite: true,
          show: false,
        },
      ],
      tooltip: {
        enabled: true,
        shared: true,
        custom: function ({ series, dataPointIndex }) {
          const sales = series[0][dataPointIndex];
          const units = series[1][dataPointIndex];
          return `<div style="padding:10px;">
                    <strong>Total Sales:</strong> $${sales}<br />
                    <strong>Units Sold:</strong> ${units} units
                  </div>`;
        },
      },
    };
  };

  return (
    <>
      <Header dashboardCount={dashboardCount} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div>
                    <Button variant="outlined" onClick={() => setOpen(true)}>
                      {state.isSelected
                        ? state.label
                        : state.isCustom
                        ? `${moment(state.startDate).format(
                            "DD/MM/YYYY"
                          )} - ${moment(state.endDate).format("DD/MM/YYYY")}`
                        : "Select Date"}
                    </Button>
                    <Dialog
                      maxWidth="xl"
                      fullScreen={width < 576}
                      open={open}
                      onClose={() => setOpen(false)}
                    >
                      <DialogTitle>
                        <Close
                          onClick={() => setOpen(false)}
                          className="close-btn"
                        />
                      </DialogTitle>
                      <div className="d-flex justify-content-center align-items-center">
                        {width < 768 ? (
                          <DateRange
                            state={state}
                            onChange={(item) => setState(item)}
                            onClose={() => setOpen(false)}
                          />
                        ) : width < 992 ? (
                          <DateRangePicker
                            state={state}
                            onChange={(item) => setState(item)}
                            onClose={() => setOpen(false)}
                          />
                        ) : (
                          <DateRangePicker
                            state={state}
                            twoMonths={true}
                            onChange={(item) => setState(item)}
                            onClose={() => setOpen(false)}
                          />
                        )}
                      </div>
                    </Dialog>
                    {/* {period === "day" && (
                      <TextField
                        type="date"
                        value={moment(startDate ? startDate : moment()).format(
                          "YYYY-MM-DD"
                        )}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    )} */}
                    {/* <NativeSelect
                      value={period}
                      className="select mt-0 ml-2"
                      input={<BootstrapInput />}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPeriod(val);
                      }}
                    >
                      {periodOptions?.map((x) => (
                        <option value={x.value}>{x.label}</option>
                      ))}
                    </NativeSelect>
                  </div> */}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <ReactApexChart
                  options={getOptions(chartData?.labels)}
                  series={series}
                  type="area"
                  height={350}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Page visits</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Page name</th>
                    <th scope="col">Visitors</th>
                    <th scope="col">Unique users</th>
                    <th scope="col">Bounce rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">/argon/</th>
                    <td>4,569</td>
                    <td>340</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 46,53%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default Index;
