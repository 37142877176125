import React, { useState } from "react";
import { Collapse } from "@material-ui/core";

const Collapsible = ({ action, defaultOpen, children }) => {
  const [collapse, setCollapse] = useState(defaultOpen || false);
  return (
    <React.Fragment>
      <p className="w-fit" onClick={() => setCollapse(!collapse)}>
        {action}
      </p>
      <Collapse in={collapse} unmountOnExit>
        {children}
      </Collapse>
    </React.Fragment>
  );
};

export default Collapsible;
