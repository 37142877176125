import React from "react";
import DialogModal from "../../components/Modal/DialogModal";

const NoteModal = ({ setOrderItem, setShowNotes, orderItem }) => {
  return (
    <DialogModal
      noPadding={false}
      yesButtonText="Close"
      onClose={() => {
        setShowNotes && setShowNotes(false);
        setOrderItem && setOrderItem(null);
      }}
      noButton={false}
      onConfirm={() => {
        setShowNotes && setShowNotes(false);
        setOrderItem && setOrderItem(null);
      }}
    >
      {orderItem?.cancelRequest?.reason && (
        <div>
          <label>Customer Reason for Cancelling: </label>
          <p style={{ fontSize: 15 }} className="mb-2">
            {orderItem?.cancelRequest?.reason}
          </p>
        </div>
      )}
      {orderItem?.cancelRequest?.cancelledBy?.user && (
        <>
          <b className="m-0">Order Cancelled By:</b>
          <div>
            <label className="m-0">Name:</label>
            <p className="m-0 mb-2">
              {orderItem?.cancelRequest?.cancelledBy?.user?.firstName}{" "}
              {orderItem?.cancelRequest?.cancelledBy?.user?.lastName}
            </p>
            <label className="m-0">Email:</label>
            <p className="m-0 mb-2">
              {orderItem?.cancelRequest?.cancelledBy?.user?.email}
            </p>
            <label className="m-0">Comment:</label>
            <p className="m-0 mb-2">
              {orderItem?.cancelRequest?.cancelledBy?.comment}
            </p>
          </div>
        </>
      )}
      {orderItem?.cancelRequest?.approvedBy?.user && (
        <>
          <b className="m-0">Cancellation Approved By:</b>
          <div>
            <label className="m-0">Name:</label>
            <p className="m-0 mb-2">
              {orderItem?.cancelRequest?.approvedBy?.user?.firstName}{" "}
              {orderItem?.cancelRequest?.approvedBy?.user?.lastName}
            </p>
            <label className="m-0">Email:</label>
            <p className="m-0 mb-2">
              {orderItem?.cancelRequest?.approvedBy?.user?.email}
            </p>
            {orderItem?.cancelRequest?.approvedBy?.comment && (
              <>
                <label className="m-0">Comment:</label>
                <p className="m-0 mb-2">
                  {orderItem?.cancelRequest?.approvedBy?.comment}
                </p>
              </>
            )}
          </div>
        </>
      )}
    </DialogModal>
  );
};

export default NoteModal;
