import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { TiImage } from "react-icons/ti";
import { Button, Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { debounce, isEmpty } from "lodash";

import ProductItem from "./ProductItem";
import Paginator from "../../Pagination";
import Header from "../../../components/Headers/DefaultHeader";
import SearchField from "../../../components/SearchFields";
import SelectorField from "../../../components/SearchFields/Selector";
import ShowEntries from "../../../components/SearchFields/ShowEntries";
import ProductSkeleton from "../../../components/Skeleton/Product";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import * as productActions from "../../../store/actions/productActions";
import { getCategories } from "../../../store/actions/categoryActions";
import * as Const from "../../../utilities";

const Product = () => {
  const dispatch = useDispatch();
  const [ids, setIds] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [query, setQuery] = useState({ page: 1 });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { categories } = useSelector((state) => state.category);
  const { trashProducts, totalTrashProduct, loading } = useSelector(
    (state) => state.product
  );

  const skeletons = Array.from({ length: 6 });

  useEffect(() => {
    if (token) {
      dispatch(getCategories({ deleted: false }, token));
    }
  }, [token]);

  useEffect(() => {
    if (!isEmpty(query) && token) {
      dispatch(
        productActions.getProducts(token, { deleted: "true", ...query })
      );
    }
  }, [query, token]);

  const onPageChange = (page) => {
    setIds([]);
    setCheckAll(false);
    setQuery({ ...query, page });
  };

  const closeDialog = () => {
    setDeleteDialog(false);
    setRestoreDialog(false);
  };

  const onConfirmDelete = () => {
    dispatch(productActions.deleteProduct(selectedProduct?._id, token));
    closeDialog();
  };

  const onConfirmRestore = () => {
    dispatch(
      productActions.trashProduct(
        selectedProduct?._id,
        { deleted: false },
        token
      )
    );
    closeDialog();
  };

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setSelectedProduct(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setSelectedProduct(data);
    }
  };

  const categorySelect = (value) => {
    if (value) {
      setQuery({ ...query, category: value, page: 1 });
    } else {
      setQuery({ ...query, category: null, page: 1 });
    }
  };

  const colorSelect = (value) => {
    setQuery({ ...query, color: value });
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setQuery({ ...query, page: 1, searchText: value });
    }, 500),
    []
  );

  const onSearch = (value) => {
    debouncedSearch(value);
  };

  const handleDeleteAll = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to permanently delete these products?`,
      confirmButtonText: "Yes, I confirm",
      showCancelButton: true,
      cancelButtonColor: "gray",
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: "#007BFF",
    });
    if (isConfirmed) {
      dispatch(productActions.deleteAllProducts({ ids, token }));
      setIds([]);
      setCheckAll(false);
      setQuery({ ...query, page: 1 });
    }
  };

  return (
    <>
      <Header headerPadding={true} />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="mb-0">Products</h3>
                  <div className="counter">{totalTrashProduct}</div>
                </div>
                <div className="d-flex gap-8 flex-wrap justify-content-between">
                  {ids?.length > 0 && (
                    <Button
                      onClick={handleDeleteAll}
                      className="bg-primary text-white px-3"
                    >
                      Delete All
                    </Button>
                  )}
                  <div className="d-flex gap-8 flex-wrap justify-content-end">
                    <ShowEntries />
                    <SearchField onChange={onSearch} />
                    <SelectorField
                      options={categories?.map((cat) => ({
                        label: cat?.name,
                        value: cat?._id,
                      }))}
                      placeholder="Select Category"
                      onChange={categorySelect}
                    />
                    <div style={{ width: 150 }}>
                      <SelectorField
                        options={Const.colors}
                        placeholder="Select Color"
                        onChange={colorSelect}
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className="position-relative">
                <Table
                  className={`align-items-center mainTable table-flush table table-sm`}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        <Checkbox
                          checked={checkAll}
                          color="primary"
                          onChange={(e) => {
                            setCheckAll(e.target.checked);
                            if (e.target.checked) {
                              setIds(trashProducts.map((ele) => ele._id));
                            } else {
                              setIds([]);
                            }
                          }}
                        />
                      </th>
                      <th scope="col" align="center">
                        <TiImage size={"20px"} />
                      </th>
                      <th scope="col">NAME</th>
                      <th scope="col">SKU</th>
                      <th scope="col">PRICE</th>
                      <th scope="col">DATE</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      skeletons.map(() => <ProductSkeleton />)
                    ) : trashProducts?.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="text-center">
                          Record not found
                        </td>
                      </tr>
                    ) : (
                      trashProducts?.map((product) => (
                        <ProductItem
                          product={product}
                          handleActions={handleActions}
                          ids={ids}
                          setIds={setIds}
                        />
                      ))
                    )}
                  </tbody>
                </Table>
              </div>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    page={query?.page ?? 1}
                    perPage={10}
                    onPageChange={onPageChange}
                    total={totalTrashProduct}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {deleteDialog && (
        <ConfirmationModal
          title={"Are you sure you want to delete?"}
          onClose={() => setDeleteDialog(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {restoreDialog && (
        <ConfirmationModal
          title={"Are you sure you want to restore?"}
          onClose={() => setRestoreDialog(false)}
          onConfirm={onConfirmRestore}
        />
      )}
    </>
  );
};

export default Product;
