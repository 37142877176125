import React from "react";
import { Link } from "react-router-dom";

import placeholder from "../../../assets/img/Placeholder.png";
import { formatDate } from "../../../utilities";
import { Checkbox } from "@material-ui/core";

const ArticleItem = (props) => {
  const { article, handleActions, ids, setIds } = props;

  const articlePic = article.cover?.url ?? placeholder;

  return (
    <tr key={article._id}>
      <td scope="col" className="text-center">
        <Checkbox
          color="primary"
          checked={ids.includes(article?._id)}
          onChange={() => {
            if (ids.includes(article?._id)) {
              setIds(ids.filter((id) => id !== article?._id));
            } else {
              setIds([...ids, article?._id]);
            }
          }}
        />
      </td>
      <td>
        <img
          src={articlePic}
          alt=""
          style={{
            width: "70px",
            height: "70px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            border: "2px double #eee",
            padding: "2px",
          }}
        />
      </td>
      <td
        style={{
          minWidth: "200px",
          maxWidth: "200px",
          whiteSpace: "normal",
        }}
      >
        <Link to={`/article/${encodeURIComponent(article?.slug)}`}>
          {article.name}
        </Link>
      </td>
      <td style={{ minWidth: "50px", maxWidth: "50px" }}>
        {article?.status === "published" ? (
          <span className="badge badge-success" style={{ fontSize: "14px" }}>
            Published
          </span>
        ) : (
          <span className="badge badge-warning" style={{ fontSize: "14px" }}>
            Pending
          </span>
        )}
      </td>
      <td style={{ minWidth: "50px", maxWidth: "50px" }}>{article.views}</td>
      <td
        style={{
          minWidth: "100px",
          maxWidth: "100px",
          whiteSpace: "normal",
        }}
      >
        {article?.category?.name}
      </td>
      <td
        style={{
          minWidth: "150px",
          maxWidth: "150px",
          whiteSpace: "normal",
        }}
      >
        {formatDate(article?.publishDate ?? article?.createdAt, true)}
      </td>
      <td
        style={{
          minWidth: "150px",
          maxWidth: "150px",
          whiteSpace: "normal",
        }}
      >
        {formatDate(article?.createdAt, true)}
      </td>
      <td className="text-right">
        <a
          className="text-primary cursor-pointer"
          onClick={() => handleActions(article, "restore")}
        >
          Restore{" | "}
        </a>
        <a
          className="text-primary cursor-pointer"
          onClick={() => handleActions(article, "delete")}
        >
          {"  "}Delete
        </a>
      </td>
    </tr>
  );
};

export default ArticleItem;
