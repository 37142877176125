import React from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { icons } from "../CustomizedActivity";
import { createActivity } from "../../../../store/actions/orderActions";
import { ACTIVITY_TYPE, emojies, formatDate } from "../../../../utilities";

const WithActions = (props) => {
  const {
    actionType,
    client,
    type,
    text,
    createdAt,
    status,
    confirmDeliveryAction,
    orderDetails,
    children,
  } = props ?? {};
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const onConfirmCancellation = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to cancel this order?`,
      confirmButtonText: "Yes, I confirm",
      showCancelButton: true,
      cancelButtonColor: "gray",
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: "#007BFF",
    });
    if (isConfirmed) {
      dispatch(
        createActivity(
          {
            orderItemId: orderDetails?._id,
            type: ACTIVITY_TYPE.CUSTOMER_CANCELLATION_CONFIRMED,
          },
          token
        )
      );
    }
  };

  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        {icons?.[type.split("-")?.[0]]?.icon &&
          icons?.[type.split("-")?.[0]]?.icon}
        <div className="detail-wrapper">
          <div className="detail" dangerouslySetInnerHTML={{ __html: text }} />
          <div className="date">{formatDate(createdAt, true)}</div>
          {actionType === "confirmOrder" ? (
            status === ACTIVITY_TYPE.ORDER_CONFIRMED ? (
              <div className="mt-2">
                <div className="disabled-text">Product Confirmed</div>
              </div>
            ) : status === ACTIVITY_TYPE.APPOINTMENT_BOOKED ? (
              <div className="mt-2">
                <div className="disabled-text">Appointment Requested</div>
              </div>
            ) : (
              <div className="action-group mt-2">
                <Button className="w-fit" disabled>
                  Approve Now
                </Button>
                <Button className="w-fit" disabled variant="danger">
                  Visit Store
                </Button>
              </div>
            )
          ) : actionType === "confirmAppointment" ? (
            status === ACTIVITY_TYPE.APPOINTMENT_CONFIRMED ? (
              <div className="disabled-text">Appointment Confirmed</div>
            ) : status === ACTIVITY_TYPE.NEW_APPOINTMENT_BOOKED ? (
              <div className="disabled-text">
                You have rescheduled {client?.firstName}&apos;s appointment to
                visit the store
              </div>
            ) : (
              <div className="action-group mt-2">
                <Button className="w-fit" disabled>
                  Confirm Appointment
                </Button>
                <Button className="w-fit" disabled variant="danger">
                  Reschedule Appointment
                </Button>
              </div>
            )
          ) : actionType === "acceptDelivery" ? (
            status === ACTIVITY_TYPE.REVIEW_SUBMITTED ? (
              <div className="disabled-text">
                Thank you, your order is complete.
              </div>
            ) : (
              <div className="mt-2">
                <Button className="w-fit" disabled>
                  Accept & Review Order
                </Button>
              </div>
            )
          ) : actionType === "confirmProduct" ? (
            status === ACTIVITY_TYPE.PRODUCT_CONFIRMED ? (
              <div className="disabled-text">Product Confirmed</div>
            ) : (
              <Button className="mt-2 w-fit" disabled>
                Approve Now
              </Button>
            )
          ) : actionType === "confirmPickupDate" ? (
            status === ACTIVITY_TYPE.CONFIRM_PICKUP_DATE ||
            status === ACTIVITY_TYPE.NEW_PICKUP_DATE ? (
              <div className="mt-2 disabled-text"> Pickup Date Pending</div>
            ) : (
              <Button className="w-fit" disabled>
                Confirm Pickup Date
              </Button>
            )
          ) : actionType === "deliverOrder" ? (
            status === ACTIVITY_TYPE.ORDER_DELIVERED ? (
              <div className="mt-2 disabled-text">Order delivered</div>
            ) : (
              <Button
                style={{ background: "#019B5D" }}
                className="w-fit border-0"
                disabled
              >
                Deliver your order
              </Button>
            )
          ) : actionType === "confirmDelivery" ? (
            status === ACTIVITY_TYPE.DELIVERY_CONFIRMED ? (
              confirmDeliveryAction === "no" ? (
                ""
              ) : (
                <div className="mt-2 disabled-text">
                  Thank you for confirming order delivery
                </div>
              )
            ) : (
              <div className="d-flex gap-3">
                <Button
                  disabled
                  className="btn btn-primary px-4 w-24 text-white"
                >
                  Yes
                </Button>
                <Button
                  disabled
                  className="btn btn-danger ml-2 px-4 w-24 text-white"
                >
                  No
                </Button>
              </div>
            )
          ) : actionType === "confirmCancellationRequest" ? (
            <div className="mt-2">
              {orderDetails?.cancelRequest?.cancelledBy && (
                <div>
                  <label>Reason for Cancelling:</label>
                  <p
                    className="message"
                    dangerouslySetInnerHTML={{
                      __html: orderDetails?.cancelRequest?.reason,
                    }}
                  ></p>
                </div>
              )}
              {status === ACTIVITY_TYPE.CUSTOMER_CANCELLATION_CONFIRMED ? (
                <div className="py-2 italic text-gray-400 text-base rounded-md">
                  Request confirmed
                </div>
              ) : status === ACTIVITY_TYPE.WITHDRAW_CANCELLATION ? (
                <div className="py-2 italic text-gray-400 text-base rounded-md">
                  Cancellation Withdrawn
                </div>
              ) : (
                <div className="flex gap-2 flex-col sm:flex-row mt-2">
                  <Button
                    onClick={onConfirmCancellation}
                    className="bg-primary text-white px-5"
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </div>
          ) : actionType === "confirmAddress" ? (
            <>
              <div className="mt-2">
                <b>Address:</b>
                <div className="italic">
                  {orderDetails?.product?.owner?.storeAddress}
                </div>
              </div>
              {type === ACTIVITY_TYPE.ADDRESS_CONFIRMED ? (
                <div className="disabled-text">Address Confirmed</div>
              ) : (
                <div className="mt-2">
                  <Button
                    className="border-0 w-fit"
                    style={{ background: "#019B5D" }}
                    disabled
                  >
                    Confirm Pickup Address
                  </Button>
                  <Button
                    className="border-0 w-fit"
                    style={{ background: "rgb(249 115 22)" }}
                    disabled
                  >
                    Update Store Address
                  </Button>
                </div>
              )}
            </>
          ) : actionType === "tips" ? (
            status === ACTIVITY_TYPE.GAVE_VENDOR_TIP ? (
              <div className="disabled-text">
                Thank you for being generous and giving a tip&nbsp;&nbsp;
                <img
                  src={emojies.heartSmiley}
                  style={{ width: 25, height: 25 }}
                  className="emoji"
                  alt="Emoji"
                />
              </div>
            ) : (
              <div className="mt-2">
                <Button
                  style={{ background: "#019B5D" }}
                  disabled
                  className="d-flex align-items-center text-nowrap py-2 px-4 w-fit border-0"
                >
                  Be Generous! Leave a tip&nbsp;&nbsp;
                  <img
                    src={emojies.crying}
                    style={{ width: 25, height: 25 }}
                    className="emoji"
                    alt="Emoji"
                  />
                </Button>
                {/* Other option for Not tipping not activated */}
                {/* <Button disabled variant="danger" className="w-36">
                  Not today
                </Button> */}
              </div>
            )
          ) : (
            <div></div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default WithActions;
