import React, { useState } from "react";
import moment from "moment";
import { DateRange as Picker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DefinedRange from "./DefinedRange";

const DateRangePicker = ({ twoMonths, state, onChange, onClose }) => {
  const [error, setError] = useState("");
  const onChangeDate = (item) => {
    const { startDate, endDate } =
      item.range1?.selection ?? item.range1 ?? item.selection ?? {};

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.month() !== end.month() || start.year() !== end.year()) {
      setError("Only 1 Month Allowed");
      return;
    }

    setError("");
    onChange({
      startDate,
      endDate,
      period: null,
      isCustom: true,
    });
    onClose();
  };

  return (
    <>
      <DefinedRange onChange={onChange} onClose={onClose} />
      <div className="d-flex flex-column">
        {error && (
          <span className="text-md text-center text-danger mb-2">{error}</span>
        )}
        {twoMonths ? (
          <Picker
            onChange={onChangeDate}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state]}
            maxDate={new Date()}
            direction="horizontal"
          />
        ) : (
          <Picker
            onChange={onChangeDate}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[state]}
            maxDate={new Date()}
            direction="vertical"
          />
        )}
      </div>
    </>
  );
};

export default DateRangePicker;
