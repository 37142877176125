export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const ADD_TO_STORE = "ADD_TO_STORE";
export const ADD_TO_STORE_SUCCESS = "ADD_TO_STORE_SUCCESS";
export const ADD_PRODUCT_VIEW = "ADD_PRODUCT_VIEW";
export const ADD_PRODUCT_VIEW_SUCCESS = "ADD_PRODUCT_VIEW_SUCCESS";
export const LIST_PRODUCT_FAILED = "LIST_PRODUCT_FAILED";
export const ADD_UPDATE_PRODUCT_FAILED = "ADD_UPDATE_PRODUCT_FAILED";
export const ADD_FLAG = "PRODUCT/ADD_FLAG";
export const ADD_FLAG_SUCCESS = "PRODUCT/ADD_FLAG_SUCCESS";
export const TRASH_PRODUCT = "TRASH_PRODUCT";
export const TRASH_PRODUCT_SUCCESS = "TRASH_PRODUCT_SUCCESS";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";

export const RESET_PRODUCT = "RESET_PRODUCT";

export const SAVE_FORM_DATA = "SAVE_FORM_DATA";

export const GET_PRODUCT_INFO = "GET_PRODUCT_INFO";
export const GET_PRODUCT_INFO_SUCCESS = "GET_PRODUCT_INFO_SUCCESS";

export const GET_VENDOR_PRODUCTS = "GET_VENDOR_PRODUCTS";
export const GET_VENDOR_PRODUCTS_SUCCESS = "GET_VENDOR_PRODUCTS_SUCCESS";

export const DELETE_ALL = "PRODUCT/DELETE_ALL";
export const DELETE_ALL_SUCCESS = "PRODUCT/DELETE_ALL_SUCCESS";

export const PRODUCT_FAILED = "PRODUCT_FAILED";
