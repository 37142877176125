import { OrderStatus } from "../../utilities";
import * as types from "../types/order";
const initialState = {
  loading: false,
  orders: [],
  totalOrders: 0,
  userOrders: [],
  userOrdersLoading: false,
  vendorOrders: [],
  vendorOrdersLoading: false,
  errorMessage: "",
  singleOrder: {},
  reviews: [],
  customerReviews: [],
};

const orderReducers = (state = initialState, actions) => {
  switch (actions.type) {
    case types.GET_ORDER: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.GET_ORDER_SUCCESS: {
      return {
        ...state,
        orders: actions.payload?.data,
        totalOrders: actions?.payload?.total,
        loading: false,
      };
    }
    case types.GET_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        items: actions.payload.orderItems,
      };

    case types.DELETE_ORDER_SUCCESS:
      let order = [...state.orders];
      order = order.filter((e) => e._id !== actions.payload);
      return (state = {
        ...state,
        order,
      });
    case types.GET_SINGLE_ORDER_SUCCESS: {
      return {
        ...state,
        singleOrder: actions.payload,
        loading: false,
      };
    }
    case types.ORDER_REFUND_SUCCESS: {
      const updatedOrders = state.orders.map((order) => {
        if (order._id === actions.payload?.orderItemId) {
          return { ...order, status: OrderStatus.REFUNDED };
        } else {
          return order;
        }
      });

      return {
        ...state,
        orders: updatedOrders,
      };
    }
    case types.USER_ORDER_REFUND_SUCCESS: {
      const updatedOrders = state.userOrders.map((order) => {
        if (order._id === actions.payload?.orderItemId) {
          return { ...order, status: OrderStatus.REFUNDED };
        } else {
          return order;
        }
      });

      return {
        ...state,
        userOrders: updatedOrders,
      };
    }
    case types.GET_VENDOR_ORDERS: {
      return {
        ...state,
        vendorOrdersLoading: true,
      };
    }
    case types.GET_VENDOR_ORDERS_SUCCESS: {
      return {
        ...state,
        vendorOrdersLoading: false,
        vendorOrders: actions.payload,
      };
    }
    case types.GET_USER_ORDERS: {
      return {
        ...state,
        userOrdersLoading: true,
      };
    }
    case types.GET_USER_ORDERS_SUCCESS: {
      return {
        ...state,
        userOrdersLoading: false,
        userOrders: actions.payload,
      };
    }
    case types.CANCEL_ORDER_REQUEST_SUCCESS:
    case types.APPROVE_CANCEL_ORDER_REQUEST_SUCCESS: {
      const updatedOrders = state.userOrders.map((order) => {
        if (order._id === actions.payload?._id) {
          return {
            ...order,
            status: OrderStatus.CANCELLED,
          };
        } else {
          return order;
        }
      });
      return {
        ...state,
        userOrdersLoading: false,
        userOrders: updatedOrders,
      };
    }
    case types.GET_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: actions?.payload,
      };
    }
    case types.RESET_ORDER: {
      return initialState;
    }
    case types.ORDER_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.GET_CUSTOMER_REVIEWS: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.GET_CUSTOMER_REVIEWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerReviews: actions?.payload,
      };
    }
    default:
      return state;
  }
};

export default orderReducers;
