/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Container,
  Alert,
} from "reactstrap";

import imageCompression from "browser-image-compression";
import Header from "../../components/Headers/DefaultHeader";
import Loading from "../../utilities/loading";
import * as categoryActions from "../../store/actions/categoryActions";
import { errorResponse } from "../../store/sagas/auth";
import { post } from "../../store/lib/Api";
import ReactLoaderSpinner from "react-loader-spinner";
import { Spinner } from "react-bootstrap";

const AddCategory = ({ location }) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { categories, loading } = useSelector((state) => state.category);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      keywords: "",
      description: "",
      image: null,
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const image = watch("image");

  useEffect(() => {
    if (location.state) {
      const { state } = location;
      const existingCategory = categories.find((e) => e._id === state);
      if (existingCategory) {
        setValue("name", existingCategory.name);
        setValue("keywords", existingCategory.keywords);
        setValue("description", existingCategory.description);
        setCategoryId(existingCategory._id);
        setIsEditing(true);
      }
    }
  }, [location.state, categories, setValue]);

  const onUploadImage = async (filesArray) => {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/jpeg",
      quality: 0.8,
    };

    const heifFiles = [];
    const normalFiles = [];

    filesArray.forEach((file) => {
      const fileType = file.name.split(".").at(-1).toLowerCase();
      if (fileType === "heif" || fileType === "heic") {
        heifFiles.push(file);
      } else {
        normalFiles.push(file);
      }
    });

    const uploadNormalFiles = async () => {
      setUploading(true);
      const uploadedNormalFiles = [];
      if (normalFiles.length === 0) return [];

      const formData = new FormData();
      for (let i = 0; i < normalFiles.length; i++) {
        const file = normalFiles[i];
        try {
          const compressedFile = await imageCompression(file, options);
          formData.append("media", compressedFile);
        } catch (error) {
          errorResponse(error);
        }
      }

      try {
        const result = await post("/users/upload-image", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });

        uploadedNormalFiles.push(...(result?.data || []));
      } catch (error) {
        errorResponse(error);
      }

      return uploadedNormalFiles;
    };

    const convertHeifFiles = async () => {
      const uploadedHeifFiles = [];
      if (heifFiles.length === 0) return [];

      const formData = new FormData();
      for (let i = 0; i < heifFiles.length; i++) {
        const file = heifFiles[i];

        try {
          formData.append("media", file);
        } catch (error) {
          errorResponse(error);
        }
      }

      try {
        const result = await post("/users/heif-converter", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });

        uploadedHeifFiles.push(...(result?.data || []));
        return result?.data || [];
      } catch (error) {
        errorResponse(error);
      }

      return uploadedHeifFiles;
    };

    const [uploadedNormalFiles, convertedHeifFiles] = await Promise.all([
      uploadNormalFiles(),
      convertHeifFiles(),
    ]);
    setUploading(false);

    const uploadedFiles = [...uploadedNormalFiles, ...convertedHeifFiles];
    setValue("image", uploadedFiles?.[0]);
  };

  const onSubmit = (data) => {
    if (isEditing) {
      dispatch(categoryActions.updateCategory(categoryId, data, token));
    } else {
      dispatch(categoryActions.addCategory(data, token));
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="6" md="6" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5 position-relative">
                <div className="text-center text-muted mb-4">
                  <p>{isEditing ? "Update category" : "Add a category"}</p>
                </div>
                <Loading
                  width={70}
                  height={70}
                  loading={loading}
                  className="kb-loading"
                />
                <Form
                  className={`${loading ? "kb-overlay" : ""}`}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <Input placeholder="Name" {...field} />
                        )}
                      />
                    </InputGroup>
                    {errors.name && (
                      <Alert color="danger">{errors.name.message}</Alert>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Controller
                        name="keywords"
                        control={control}
                        rules={{ required: "Keywords are required" }}
                        render={({ field }) => (
                          <Input
                            placeholder="Meta keywords (only first letter Cap) in this format: Kitchen counters, Bathroom counters"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors.keywords && (
                      <Alert color="danger">{errors.keywords.message}</Alert>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <Input
                            type="textarea"
                            placeholder="Meta description"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors.description && (
                      <Alert color="danger">{errors.description.message}</Alert>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="file"
                        placeholder="Image"
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={(event) => {
                          const filesArray = Array.from(event.target.files);
                          onUploadImage(filesArray);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  {uploading ? (
                    <Spinner />
                  ) : (
                    image?.url && (
                      <img src={image?.url} width={100} height={100} />
                    )
                  )}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color={isEditing ? "success" : "primary"}
                      type="submit"
                      disabled={loading}
                    >
                      {isEditing ? "Update" : "Add"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCategory;
