import { Skeleton } from "@material-ui/lab";
import React from "react";

const ArticleSkeleton = ({ forTrash }) => {
  return forTrash ? (
    <tr>
      <td>
        <Skeleton variant="rect" width={60} height={60} />
      </td>
      <td>
        <div className="d-flex flex-column">
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      </td>
      <td align="center">
        <Skeleton variant="text" width={20} />
      </td>
      <td>
        <Skeleton variant="text" />
      </td>
      <td>
        <Skeleton variant="text" />
      </td>
      <td>
        <Skeleton variant="text" />
      </td>
      <td>
        <Skeleton variant="text" />
      </td>
      <td>
        <div className="d-flex gap-4">
          <Skeleton variant="text" width={40} />
          <Skeleton variant="text" width={40} />
        </div>
      </td>
    </tr>
  ) : (
    <tr>
      <td>
        <Skeleton variant="rect" width={70} height={70} />
      </td>
      <td>
        <div className="d-flex flex-column">
          <Skeleton variant="text" width={180} height={20} />
          <Skeleton variant="text" width={180} height={20} />
          <Skeleton variant="text" width={180} height={20} />
        </div>
      </td>
      <td align="center">
        <Skeleton variant="text" width={50} />
      </td>
      <td>
        <Skeleton variant="text" width={180} />
      </td>
      <td>
        <Skeleton variant="text" width={50} />
      </td>
      <td>
        <Skeleton variant="text" width={50} />
      </td>
      <td>
        <Skeleton variant="text" width={50} />
      </td>
      <td>
        <Skeleton variant="text" width={50} />
      </td>
      <td>
        <Skeleton variant="text" width={100} />
      </td>
      <td>
        <Skeleton variant="text" width={130} />
      </td>
      <td>
        <Skeleton variant="text" width={130} />
      </td>
      <td>
        <Skeleton variant="text" width={140} />
      </td>
      <td>
        <Skeleton variant="text" width={120} />
      </td>
      <td>
        <Skeleton variant="text" width={120} />
      </td>
      <td>
        <Skeleton variant="text" width={100} height={60} />
      </td>
      <td>
        <Skeleton variant="text" width={40} height={40} />
      </td>
    </tr>
  );
};

export default ArticleSkeleton;
