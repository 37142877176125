import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import Paginator from "../Pagination";
import Header from "./../../components/Headers/DefaultHeader";
import SearchField from "./../../components/SearchFields";
import * as userActions from "./../../store/actions/userActions";
import "../User/User.css";
import { get } from "../../store/lib/Api";
import { scrollTo } from "../../utilities";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useHistory } from "../../contexts/AppProvider";
import { Link } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
import { totalMoneySpent } from "../User/UserList";

const Users = (props) => {
  const { token } = useSelector((state) => state.auth);
  const { trashUser, totalTrashUser, loading } = useSelector(
    (state) => state.user
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({ start: 0, end: 10 });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const { start, end } = pagination;

  useEffect(() => {
    token &&
      dispatch(
        userActions.getUsers(token, { page, deleted: true, user: "true" })
      );
  }, [token]);

  useEffect(() => {
    setUserList(trashUser);
  }, [trashUser]);

  useEffect(() => {
    const id = history.location.search?.split("=")?.[1];
    const index = userList.findIndex((user) => user._id === id);
    if (index !== -1) {
      const page = Math.floor(index / 10) + 1;
      setPage(page);
    }
    setTimeout(() => {
      scrollTo(id, 150);
    }, 1000);
  }, []);

  const nameSearch = (value) => {
    if (value) {
      let searchText = value?.toLowerCase();
      const filter = userList.filter((user) => {
        return (
          user.email?.toLowerCase()?.includes(searchText) ||
          user.firstName?.toLowerCase()?.includes(searchText) ||
          user.lastName?.toLowerCase()?.includes(searchText)
        );
      });
      setUserList(filter);
    } else {
      setUserList(trashUser);
    }
  };

  const onPageChange = (page) => {
    setPage(page);
    token &&
      dispatch(
        userActions.getUsers(token, { page, deleted: true, user: "true" })
      );
  };

  const onContact = (email) => {
    get(`/users/hubspotContact?email=${email}`, {
      Authorization: `Bearer ${token}`,
    }).then((data) => {
      if (data?.contactId) {
        window.open(
          `https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_ID}/contact/${data?.contactId}`,
          "_blank"
        );
      }
    });
  };

  const closeModal = () => {
    setDeleteDialog(false);
    setRestoreDialog(false);
  };

  const onConfirmRestore = () => {
    dispatch(
      userActions.trashUser({
        id: userDetails?._id,
        payload: { deleted: false },
        token,
      })
    );
    closeModal();
  };

  const onConfirmDelete = () => {
    dispatch(userActions.deleteUser(userDetails?._id, token));
    closeModal();
  };

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setUserDetails(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setUserDetails(data);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Header headerPadding={true} />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Users</h3>
              </CardHeader>
              <div className="HeaderWrapper">
                <SearchField onChange={nameSearch} />
              </div>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">NAME</th>
                    <th scope="col">Purchase Orders</th>
                    <th scope="col">Total $$ Spents</th>
                    <th scope="col">ACTIONS</th>
                  </tr>
                </thead>
                {userList?.length > 0 &&
                  userList?.slice(start, end)?.map((user) => (
                    <tr
                      key={user?._id}
                      id={user?._id}
                      className={
                        history?.location?.search?.split("=")?.[1] === user?._id
                          ? "splash"
                          : ""
                      }
                    >
                      <td>
                        <Link to={`/dashboard/user/${user?._id}`}>
                          <BsEyeFill className="cursor-pointer" size={15} />
                        </Link>
                      </td>
                      <td
                        className="cursor-pointer"
                        onClick={() => onContact(user?.email)}
                      >
                        {user?.email}
                      </td>
                      <td>
                        {user?.firstName} {user?.lastName}
                      </td>
                      <td>{user?.orderItems?.length}</td>
                      <td>${totalMoneySpent(user?.orderItems)}</td>
                      <td>
                        <a
                          className="text-primary cursor-pointer"
                          onClick={() => handleActions(user, "restore")}
                        >
                          Restore{" | "}
                        </a>
                        <a
                          className="text-primary cursor-pointer"
                          onClick={() => handleActions(user, "delete")}
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    page={page}
                    perPage={10}
                    total={totalTrashUser}
                    onPageChange={onPageChange}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        {restoreDialog && (
          <ConfirmationModal
            title="Are you sure you want to restore?"
            onClose={closeModal}
            onConfirm={onConfirmRestore}
          />
        )}
        {deleteDialog && (
          <ConfirmationModal
            title="Are you sure you want to delete?"
            onClose={closeModal}
            onConfirm={onConfirmDelete}
          />
        )}
      </Container>
    </>
  );
};

export default Users;
