import React, { useState } from "react";
import moment from "moment";

const DefinedRange = ({ onChange, onClose }) => {
  const [active, setActive] = useState("");
  const staticRanges = [
    {
      label: "Today",
      period: "today",
      range: {
        startDate: moment().startOf("day"),
        endDate: moment().endOf("day"),
      },
    },
    {
      label: "Yesterday",
      period: "yesterday",
      range: {
        startDate: moment().subtract(1, "day").startOf("day"),
        endDate: moment().subtract(1, "day").endOf("day"),
      },
    },
    {
      label: "This week (Sun - Today)",
      period: "thisWeek",
      range: {
        startDate: moment().startOf("week"),
        endDate: moment(),
      },
    },
    {
      label: "Last 7 days",
      period: "last7Days",
      range: {
        startDate: moment().subtract(7, "days"),
        endDate: moment(),
      },
    },
    {
      label: "Last week (Sun - Sat)",
      period: "lastWeek",
      range: {
        startDate: moment().subtract(1, "week").startOf("week"),
        endDate: moment().subtract(1, "week").endOf("week"),
      },
    },
    {
      label: "Last 14 days",
      period: "last14Days",
      range: {
        startDate: moment().subtract(14, "days"),
        endDate: moment(),
      },
    },
    {
      label: "This month",
      period: "thisMonth",
      range: {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
    },
    {
      label: "Last 30 days",
      period: "last30Days",
      range: {
        startDate: moment().subtract("30", "days"),
        endDate: moment(),
      },
    },
    {
      label: "Last month",
      period: "lastMonth",
      range: {
        startDate: moment().subtract(1, "month").startOf("month"),
        endDate: moment().subtract(1, "month").endOf("month"),
      },
    },
    {
      label: "All time",
      period: "allTime",
      range: {
        startDate: moment().subtract(1, "year"),
        endDate: moment(),
      },
    },
  ];

  const handleRangeChange = ({ range, period, label }) => {
    setActive(period);
    onClose();
    onChange({ ...range, isSelected: true, period, label });
  };

  return (
    <div className="d-flex flex-column gap-8 align-items-start ml-4">
      {staticRanges.map(({ label, period, range }) => (
        <button
          key={period}
          className={`px-2 py-1 rounded border m-0 ${
            active === period ? "btn bg-primary text-white" : "btn bg-secondary"
          }`}
          onClick={() => handleRangeChange({ range, period, label })}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default DefinedRange;
