/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
// core components
import Header from "../../components/Headers/DefaultHeader.jsx";
import { connect } from "react-redux";
import * as categoryActions from "../../store/actions/categoryActions.js";
import SearchField from "../../components/SearchFields/index.jsx";
import "../Category/Category.css";
//import SearchBar from "./../../components/Searchbars";
import CustomPagination from "../Pagination/pagination.js";
import Loading from "../../utilities/loading.js";
import { showMessageError } from "../../utilities/errorHandle.js";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ActionButton from "./ActionButton.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      searching: false,
      start: null,
      end: null,
      deleteDialog: false,
      selectedCategory: null,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount = () => {
    const { auth } = this.props;
    if (auth?.token) this.props.getCategories({ deleted: false }, auth?.token);
    this.setState({ categories: this.props.category.categories });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.category.categories !== prevState.categories &&
      !prevState.searching
    ) {
      return { categories: nextProps.category.categories };
    }

    if (nextProps?.category?.loading !== prevState?.category?.loading) {
      return { loading: nextProps?.category?.loading };
    }
    if (nextProps?.category?.error !== prevState?.category?.error) {
      if (
        nextProps?.category?.error &&
        nextProps?.category?.error.toString() != ""
      ) {
        showMessageError(nextProps?.category?.error.toString());
      }
      return { errorMsg: nextProps?.category?.error };
    }

    return null;
  }

  handleDelete = (selectedCategory) => {
    const { token } = this.props.auth;
    this.setState({ selectedCategory, deleteDialog: true });
  };

  closeModal = () => {
    this.setState({ deleteDialog: false });
  };

  onConfirmDelete = () => {
    const { selectedCategory } = this.state;
    const { token } = this.props.auth;
    this.props.trashCategory(selectedCategory._id, { deleted: true }, token);
    this.closeModal();
  };

  onSearchChange = (value) => {
    const { categories } = this.state;
    if (value) {
      const filterCategories = categories.filter((el) => {
        let searchValue = el.name.toLowerCase();
        return searchValue.includes(value.toLowerCase());
      });
      this.setState({
        categories: filterCategories,
        searching: true,
      });
    } else {
      this.setState({
        categories: this.props.category.categories,
        searching: false,
      });
    }
  };
  handleEdit = (categoryId) => {
    this.props.history.push("/dashboard/add-category", categoryId);
  };

  handleCategory = () => {
    this.props.history.push("/dashboard/add-category");
  };

  paginationHandler = (start, end) => {
    this.setState({
      start,
      end,
    });
  };

  handleHide = (id, status) => {
    const { token } = this.props.auth;
    this.props.hideCategory(id, { hide_Status: !!status }, token);
  };
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  onDragEnd(result) {
    if (this.props.loading == true) {
      return;
    } else {
      if (!result.destination) {
        // dropped outside the list
        return;
      }

      const testcategories = this.reorder(
        this.state.categories,
        result.source.index,
        result.destination.index
      );

      // this.setState(prevState => {return { ...prevState, categories: testcategories }} );
      this.props.getCategoriesSuccess(testcategories);
      let listOfCateId = [];
      for (const cate of testcategories) {
        listOfCateId.push(cate._id);
      }
      const { token } = this.props.auth;
      this.props.updateCategoryPriority(listOfCateId, token);
    }
  }

  render() {
    const { categories } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Categories</h3>
                </CardHeader>
                <div className="HeaderWrapper">
                  <SearchField onChange={this.onSearchChange} />
                  <Button
                    onClick={this.handleCategory}
                    type="button"
                    className="m-0"
                  >
                    Add Category
                  </Button>
                </div>
                <div className="position-relative">
                  <Loading
                    width={70}
                    height={70}
                    loading={this.props.loading}
                    className="kb-loading-table"
                  />
                  <div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="Table">
                        {(provided) => (
                          <div
                            className="kb-table-draggable"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light TableData">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">IMAGE</th>
                                  <th scope="col">NAME</th>
                                  <th scope="col">META KEYWORDS</th>
                                  <th scope="col">META DESCRIPTION</th>
                                  <th scope="col">VISIBILITY</th>
                                  <th scope="col" className="text-right">
                                    ACTIONS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {categories
                                  .slice(this.state.start, this.state.end)
                                  .map((cate, i) => {
                                    return (
                                      <Draggable
                                        isDragDisabled={
                                          this.state.searching ||
                                          this.props.loading
                                        }
                                        draggableId={cate._id}
                                        key={cate._id}
                                        index={i}
                                      >
                                        {(provided) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <td>
                                              <div className="kb-move-icon">
                                                <svg
                                                  aria-hidden="true"
                                                  focusable="false"
                                                  data-prefix="fas"
                                                  data-icon="grip-vertical"
                                                  role="img"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 320 512"
                                                >
                                                  <path
                                                    fill="currentColor"
                                                    d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
                                                  ></path>
                                                </svg>
                                              </div>
                                            </td>
                                            <td>
                                              <img
                                                className="rounded"
                                                src={cate.image?.url}
                                                width={100}
                                                height={100}
                                              />
                                            </td>
                                            <td>{cate.name}</td>
                                            <td
                                              style={{
                                                minWidth: 300,
                                                maxWidth: 300,
                                                textWrap: "wrap",
                                              }}
                                            >
                                              {cate.keywords}
                                            </td>
                                            <td
                                              style={{
                                                minWidth: 300,
                                                maxWidth: 300,
                                                textWrap: "wrap",
                                              }}
                                            >
                                              {cate.description}
                                            </td>
                                            <td>
                                              {cate.hide_Status
                                                ? "Hidden"
                                                : "Visible"}
                                            </td>
                                            <td className="text-right">
                                              <ActionButton
                                                category={cate}
                                                handleEdit={this.handleEdit}
                                                handleDelete={this.handleDelete}
                                                handleHide={this.handleHide}
                                              />
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                {provided.placeholder}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <CustomPagination
                      showPerPage={10}
                      paginationHandler={this.paginationHandler}
                      total={categories?.length}
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        {this.state.deleteDialog && (
          <ConfirmationModal
            title="Are you sure you want to delete?"
            onClose={() => this.setState({ deleteDialog: false })}
            onConfirm={this.onConfirmDelete}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    category: state.category,
    loading: state.category.loading,
    errorMsg: state.category.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (query, token) =>
      dispatch(categoryActions.getCategories(query, token)),
    getCategoriesSuccess: (data) =>
      dispatch(categoryActions.getCategoriesSuccess(data)),
    hideCategory: (id, category, token) =>
      dispatch(categoryActions.hideCategory(id, category, token)),
    updateCategoryPriority: (category, token) =>
      dispatch(categoryActions.updateCategoryPriority(category, token)),
    trashCategory: (id, payload, token) => {
      dispatch(categoryActions.trashCategory(id, payload, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
