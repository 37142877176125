import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { GoLocation } from "react-icons/go";
import { IoMdCall, IoIosAt } from "react-icons/io";

import Header from "../../components/Headers/DefaultHeader.jsx";
import SearchField from "../../components/SearchFields/index.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";
import CustomPagination from "../Pagination/pagination.js";
import * as userActions from "../../store/actions/userActions.js";
import Loading from "../../utilities/loading.js";
import { formatDate } from "../../utilities/index.js";
import { NativeSelect } from "@material-ui/core";
import BootstrapInput from "../../components/UI/BootstrapInput.jsx";

const statusOptions = [
  { label: "Sold", value: "sold" },
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];

const StoreVendor = (props) => {
  const {
    auth,
    vendors,
    getVendor,
    trashStore,
    updateVendorStatus,
    deleteVendor,
    loading,
  } = props;
  const [vendorList, setVendorList] = useState([]);
  const [pagination, setPagination] = useState({ start: 0, end: 10 });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [storeDetails, setStoreDetails] = useState(null);

  const { start, end } = pagination;

  useEffect(() => {
    auth?.token && getVendor(auth?.token, { deleted: true });
  }, [auth?.token]);

  useEffect(() => {
    setVendorList(vendors);
  }, [vendors]);

  const nameSearch = (value) => {
    if (value) {
      const filter = vendors.filter((user) =>
        user?.email?.toLowerCase().match(value?.toLowerCase())
      );
      setVendorList(filter);
    } else {
      setVendorList(vendors);
    }
  };

  const closeModal = () => {
    setDeleteDialog(false);
    setRestoreDialog(false);
  };

  const onConfirmRestore = () => {
    trashStore(storeDetails?._id, { deleted: false }, auth?.token);
    closeModal();
  };

  const onConfirmDelete = () => {
    deleteVendor(storeDetails?._id, auth?.token);
    closeModal();
  };

  const paginationHandler = (start, end) => setPagination({ start, end });

  const changeStatus = (status, id) => {
    updateVendorStatus({ status, id }, auth?.token);
  };

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setStoreDetails(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setStoreDetails(data);
    }
  };

  return (
    <>
      <Header headerPadding={true} />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Store Vendor</h3>
              </CardHeader>
              <div className="HeaderWrapper">
                <SearchField onChange={nameSearch} />
              </div>
              <div className="position-relative">
                <Loading
                  width={70}
                  height={70}
                  loading={loading}
                  className="kb-loading-table"
                />
                <Table
                  className={`${
                    loading ? "kb-overlay" : ""
                  } "align-items-center table-flush"`}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">VERIFICATION PROFILE</th>
                      <th scope="col">STORE</th>
                      <th scope="col">Vendor Status</th>
                      <th scope="col">Status</th>
                      <th scope="col">Register Date</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorList?.length > 0 &&
                      vendorList?.slice(start, end).map((vendorStore) => {
                        return (
                          <tr key={vendorStore?._id}>
                            <td
                              style={{
                                minWidth: "300px",
                                maxWidth: "300px",
                                textWrap: "wrap",
                              }}
                            >
                              <IoIosAt
                                style={{ size: "40px", color: "#5DADE2" }}
                              />
                              {vendorStore?.email}
                              <span>
                                <br />
                                <IoMdCall
                                  style={{ size: "40px", color: "#5DADE2" }}
                                />
                                {vendorStore?.phone}
                                <br />
                                <GoLocation
                                  style={{ size: "40px", color: "#5DADE2" }}
                                />
                                {vendorStore?.storeAddress}
                              </span>
                            </td>
                            <td>{vendorStore?.storeName}</td>
                            <td style={{ width: 130 }}>
                              <NativeSelect
                                id="demo-customized-select-native"
                                input={<BootstrapInput />}
                                value={
                                  statusOptions.find(
                                    (ele) => ele?.value == vendorStore?.status
                                  )?.value ?? statusOptions[0].value
                                }
                                onChange={(event) => {
                                  changeStatus(
                                    event.target.value,
                                    vendorStore?._id
                                  );
                                }}
                              >
                                {statusOptions?.map((option) => (
                                  <option
                                    key={option?.label}
                                    value={option?.value}
                                  >
                                    {option?.label}
                                  </option>
                                ))}
                              </NativeSelect>
                            </td>
                            <td className="pr-0 pl-0 mr-0 ml-0 text-center">
                              {vendorStore?.activate ? "Active" : "Inactive"}
                            </td>
                            <td>{formatDate(vendorStore?.createdAt, true)}</td>
                            <td>
                              <a
                                className="text-primary cursor-pointer"
                                onClick={() =>
                                  handleActions(vendorStore, "restore")
                                }
                              >
                                Restore{" | "}
                              </a>
                              <a
                                className="text-primary cursor-pointer"
                                onClick={() =>
                                  handleActions(vendorStore, "delete")
                                }
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    {vendorList?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <div className="w-100">
                            <div className="d-flex justify-content-center w-100">
                              Record not found
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <CustomPagination
                      showPerPage={10}
                      paginationHandler={paginationHandler}
                      total={vendors.length}
                    />
                  </nav>
                </CardFooter>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
      {restoreDialog && (
        <ConfirmationModal
          title="Are you sure you want to restore?"
          onClose={closeModal}
          onConfirm={onConfirmRestore}
        />
      )}
      {deleteDialog && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onClose={closeModal}
          onConfirm={onConfirmDelete}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    vendors: state.user.vendors,
    loading_: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendor: (token, query) => dispatch(userActions.getVendor(token, query)),
    removeSuccessMessage: () => dispatch(userActions.removeSuccessMessage()),
    deleteVendor: (id, token) => dispatch(userActions.deleteVendor(id, token)),
    trashStore: (id, payload, token) =>
      dispatch(userActions.trashStore(id, payload, token)),
    updateVendorStatus: (data, token) =>
      dispatch(userActions.updateVendorStatus(data, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreVendor);
