import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./../types/product";
import * as service from "./../services/product";
import * as actions from "./../actions/productActions";
import { push } from "react-router-redux";
import { errorResponse, successResponse } from "./auth";
import { get, post } from "../lib/Api";
import { objectToQueryParams } from "../../utilities";

function* getProduct({ token, query }) {
  try {
    const result = yield call(service.products, token, query);
    yield put(
      actions.getProductsSuccess({ ...(result?.data ?? {}), ...query })
    );
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* addViews(action) {
  const { id, token } = action.payload;
  try {
    const result = yield call(service.addView, id, token);
    if (result.status === 200) {
      yield put(actions.addViewSuccess(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* addToStore(action) {
  const { id, token } = action.payload;
  try {
    const result = yield call(service.addToStore, id, token);
    if (result.status === 200) {
      yield put(actions.addToStoreSuccess(result.data.data));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* addProduct(action) {
  try {
    const { product, token } = action.payload;
    const result = yield call(service.addProduct, product, token);
    if (result.status === 201) {
      yield put(actions.addProductSuccess(result.data.data));
      yield put(push("/dashboard/products"));
    } else if (result.status === 404 || result.status === 500) {
      yield put(actions.addUpdateProductFailed(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.addUpdateProductFailed(error.toString()));
  }
}

function* deleteProduct(action) {
  try {
    const { id, token } = action.payload;
    yield call(service.deleteProduct, id, token);
    yield put(actions.deleteProductSuccess({ _id: id }));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.listProductFailed(error.toString()));
  }
}
function* updateProduct(action) {
  try {
    const { id, product, token } = action.payload;
    const result = yield call(service.updateProduct, id, product, token);
    yield put(actions.updateProductSuccess());
    successResponse(result);
    yield put(push("/dashboard/products"));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.addUpdateProductFailed(error.toString()));
  }
}

function* addFlag({ id, payload, token }) {
  try {
    const result = yield call(service.addFlag, id, payload, token);
    yield put(actions.addFlagSuccess(result.data.data));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* trashProduct({ id, payload, token }) {
  try {
    const result = yield call(service.trashProduct, id, payload, token);
    yield put(actions.trashProductSuccess({ _id: id, ...payload }));
    successResponse(result);
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* changeStatus({ id, status, token }) {
  try {
    const result = yield call(service.changeStatus, id, status, token);
    yield put(actions.changeStatusSuccess({ id, status }));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
  }
}

function* getProductInfo({ id }) {
  try {
    const result = yield call(() =>
      get(`/products/${id}`, {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      })
    );
    yield put(actions.getProductInfoSuccess(result.data));
  } catch (error) {
    errorResponse(error);
  }
}

function* getVendorProduct({ token, query }) {
  try {
    const q = objectToQueryParams(query);
    const result = yield call(() =>
      get(`/products${q}`, {
        authorization: `Bearer ${token}`,
      })
    );
    yield put(actions.getVendorProductsSuccess(result));
  } catch (error) {
    errorResponse(error);
  }
}

function* deleteAll({ ids, token, trash }) {
  try {
    if (trash) {
      const result = yield call(() =>
        post(
          `/products/update-many`,
          { ids },
          { authorization: `Bearer ${token}` }
        )
      );
      successResponse(result);
    } else {
      const result = yield call(() =>
        post(
          `/products/delete-many`,
          { ids },
          { authorization: `Bearer ${token}` }
        )
      );
      successResponse(result);
    }
    yield put(actions.deleteAllProductsSuccess({ ids, trash }));
  } catch (error) {
    errorResponse(error);
  }
}

export default function* productWatcher() {
  yield takeLatest(types.GET_PRODUCTS, getProduct);
  yield takeLatest(types.ADD_PRODUCT, addProduct);
  yield takeLatest(types.DELETE_PRODUCT, deleteProduct);
  yield takeLatest(types.UPDATE_PRODUCT, updateProduct);
  yield takeLatest(types.ADD_TO_STORE, addToStore);
  yield takeLatest(types.ADD_PRODUCT_VIEW, addViews);
  yield takeLatest(types.ADD_FLAG, addFlag);
  yield takeLatest(types.TRASH_PRODUCT, trashProduct);
  yield takeLatest(types.CHANGE_STATUS, changeStatus);
  yield takeLatest(types.GET_PRODUCT_INFO, getProductInfo);
  yield takeLatest(types.GET_VENDOR_PRODUCTS, getVendorProduct);
  yield takeLatest(types.DELETE_ALL, deleteAll);
}
