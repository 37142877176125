import * as types from "../types/article";
const initialState = {
  articles: [],
  loading: false,
  article: null,
  error: "",
  totalArticle: 0,
  articleInfo: null,
  trashArticles: [],
  totalTrashArticle: 0,
};

const articleReducers = (state = initialState, actions) => {
  switch (actions.type) {
    // Failed List Articles
    case types.LIST_ARTICLE_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
      };
    }
    case types.ADD_UPDATE_ARTICLE_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
      };
    }
    case types.UPDATE_ARTICLE_PRIORITY: {
      return { ...state, loading: true };
    }
    case types.UPDATE_ARTICLE_PRIORITY_SUCCESS: {
      return { ...state, loading: false };
    }
    case types.GET_ARTICLES:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ARTICLES_SUCCESS: {
      if (actions.deleted) {
        return {
          ...state,
          loading: false,
          totalTrashArticle: actions.total,
          trashArticles: actions.data,
        };
      }
      return {
        ...state,
        articles: actions.data,
        totalArticle: actions.total,
        loading: false,
      };
    }
    //Delete Article
    case types.DELETE_ARTICLE:
    case types.TRASH_ARTICLE:
      return { ...state, loading: true };
    case types.DELETE_ARTICLE_SUCCESS:
    case types.TRASH_ARTICLE_SUCCESS: {
      if (!actions?.deleted) {
        const trashArticles = state?.trashArticles ?? [];
        const filteredArticle = trashArticles.filter(
          (e) => e._id !== actions?.id
        );
        return (state = {
          ...state,
          trashArticles: filteredArticle,
          loading: false,
        });
      } else {
        const articles = state?.articles ?? [];
        const filteredArticle = articles.filter((e) => e._id !== actions?._id);
        return (state = {
          ...state,
          articles: filteredArticle,
          loading: false,
        });
      }
    }

    case types.PUBLISH_ARTICLE:
      return { ...state, loading: true };
    case types.PUBLISH_ARTICLE_SUCCESS: {
      const articles = state.articles.map((ele) => {
        if (ele._id === actions.data._id) {
          return { ...ele, status: actions.data?.status };
        } else {
          return ele;
        }
      });
      return {
        ...state,
        articles,
        loading: false,
        articleInfo: { ...state.articleInfo, ...actions?.data },
      };
    }
    //Add View
    case types.ADD_ARTICLE: {
      return { ...state, loading: true };
    }
    case types.ADD_ARTICLE_VIEW_SUCCESS: {
      const articles = [...state.articles];
      const index = articles.findIndex((e) => e._id === actions.data._id);
      articles[index].views = actions.data.views;
      return { ...state, articles, loading: false };
    }
    case types.UPDATE_ARTICLE:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        articles: [...state.articles, actions.payload],
      };
    case types.UPDATE_ARTICLE_SUCCESS: {
      const newArr = state.articles?.map((article) => {
        if (article?._id == actions?.payload?._id) {
          return { ...article, ...actions.payload };
        } else {
          return article;
        }
      });
      return { ...state, articles: newArr, loading: false };
    }
    case types.ADD_FLAG_SUCCESS: {
      const payload = actions.payload;
      const filteredArticles = state.articles.map((ele) => {
        if (ele?._id == payload?._id) {
          return { ...ele, ...payload };
        } else {
          return ele;
        }
      });
      return {
        ...state,
        articles: filteredArticles,
      };
    }
    case types.GET_ARTICLE_SUCCESS: {
      return {
        ...state,
        article: actions?.payload,
      };
    }
    case types.DELETE_COMMENT_SUCCESS: {
      const filteredComment = state.article.comments?.filter(
        (comment) => comment._id != actions.payload
      );
      return {
        ...state,
        article: { ...state.article, comments: filteredComment },
      };
    }
    case types.ARTICLE_FAILED: {
      return { ...state, loading: false };
    }
    case types.GET_ARTICLE_INFO: {
      return { ...state, loading: true };
    }
    case types.GET_ARTICLE_INFO_SUCCESS: {
      return { ...state, loading: false, articleInfo: actions.payload };
    }
    case types.DELETE_ALL_SUCCESS: {
      const payload = actions.payload;
      if (payload?.trash) {
        const filtered = state.articles?.filter(
          (ele) => !payload.ids.includes(ele?._id)
        );
        return {
          ...state,
          loading: false,
          articles: filtered,
          totalArticle: state.articles?.length - filtered?.length,
        };
      } else {
        const filtered = state.trashArticles?.filter(
          (ele) => !payload.ids.includes(ele?._id)
        );
        return {
          ...state,
          loading: false,
          trashArticles: filtered,
          totalTrashArticle: state.trashArticles?.length - filtered?.length,
        };
      }
    }
    case types.RESET_ARTICLE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default articleReducers;
