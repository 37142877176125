import React from "react";
import { useSelector } from "react-redux";
import {
  activityCustomDate,
  feeCalculator,
  formatDate,
  prices,
} from "../../../utilities";

const Addons = ({ orderDetails, addons, product, forVendor }) => {
  const { config } = useSelector((state) => state.setting);

  return (
    <div
      className="border-top mt-4 overflow-hidden"
      style={{ borderTop: "2px solid #ccc" }}
    >
      <div className="d-flex justify-content-between align-items-center py-3 px-3 bg-light">
        <div className="h3 mb-0 fw-semibold">
          Add Services{" "}
          <span className="h5 font-italic">
            {formatDate(addons?.[0]?.createdAt, true)}
          </span>
        </div>
      </div>
      {addons?.length &&
        addons?.map(
          (item, index) =>
            Object.values(item).length > 1 && (
              <div key={index}>
                {item?.pickupChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div className="d-flex gap-1" style={{ width: "60%" }}>
                      <div>{item?.pickupChoice?.mode}</div>
                      {item?.pickupChoice?.price === "TBD"
                        ? ""
                        : orderDetails?.deliveryDate && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `by ${activityCustomDate(
                                  new Date(
                                    orderDetails?.deliveryDate
                                  ).getTime() <
                                    new Date(item?.deliveryDate).getTime()
                                    ? item?.deliveryDate
                                    : orderDetails?.deliveryDate,
                                  true
                                )} (${
                                  (item?.pickupChoiceDay ??
                                    orderDetails?.pickupChoiceDay) +
                                  (orderDetails?.totalSpecs > 1
                                    ? orderDetails?.totalSpecs - 1
                                    : 0)
                                } Day)`,
                              }}
                            ></span>
                          )}
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      {item?.pickupChoicePrice
                        ? item?.pickupChoicePrice != 0
                          ? `$${
                              forVendor
                                ? feeCalculator({
                                    config,
                                    total: item?.pickupChoicePrice,
                                  }).totalFees
                                : item?.pickupChoicePrice
                            }`
                          : `$${
                              forVendor
                                ? feeCalculator({
                                    config,
                                    total: orderDetails?.pickupChoicePrice,
                                  }).totalFees
                                : orderDetails?.pickupChoicePrice
                            }`
                        : item?.pickupChoice?.price === "TBD"
                        ? "TBD"
                        : ""}
                    </div>
                  </div>
                )}
                {item?.fabricationChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div style={{ width: "60%" }}>
                      <div>{item?.fabricationChoice?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      $
                      {forVendor
                        ? feeCalculator({
                            config,
                            total: prices(
                              product,
                              item.fabricationChoice,
                              "Fabrication"
                            ),
                          }).totalFees
                        : prices(
                            product,
                            item.fabricationChoice,
                            "Fabrication"
                          )}
                    </div>
                  </div>
                )}
                {item?.edgeChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div style={{ width: "60%" }}>
                      <div>{item?.edgeChoice?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      $
                      {forVendor
                        ? feeCalculator({
                            config,
                            total: item.edgeChoice.price,
                          }).totalFees
                        : item.edgeChoice.price}
                    </div>
                  </div>
                )}
                {item?.installationChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div style={{ width: "60%" }}>
                      <div>{item?.installationChoice?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      $
                      {forVendor
                        ? feeCalculator({
                            config,
                            total: prices(
                              product,
                              item?.installationChoice,
                              "Installation"
                            ),
                          }).totalFees
                        : prices(
                            product,
                            item?.installationChoice,
                            "Installation"
                          )}
                    </div>
                  </div>
                )}
                {item?.cutoutChoice?.map((option, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between py-3 px-3"
                  >
                    <div style={{ width: "60%" }}>
                      <div>(Cutout) {option?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>{option.count}</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      $
                      {forVendor
                        ? feeCalculator({
                            config,
                            total: option?.price * option.count,
                          }).totalFees
                        : option?.price * option.count}
                    </div>
                  </div>
                ))}
                {item?.addedServices?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between py-3 px-3"
                  >
                    <div style={{ width: "60%" }}>
                      <div>{item?.description}</div>
                    </div>
                    <div style={{ width: "20%" }}>{item?.quantity}</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      $
                      {forVendor
                        ? feeCalculator({
                            config,
                            total: item?.price,
                          }).totalFees
                        : item?.price}
                    </div>
                  </div>
                ))}
              </div>
            )
        )}
    </div>
  );
};

export default Addons;
