import React from "react";
import { BiRocket, BiFile, BiPencil, BiSolidChevronDown } from "react-icons/bi";
import {
  MdOutlineDateRange,
  MdPendingActions,
  MdSchedule,
} from "react-icons/md";
import { TbApps, TbPigMoney } from "react-icons/tb";
import { FaHandshake, FaMapMarkerAlt } from "react-icons/fa";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FcApproval, FcOk } from "react-icons/fc";
import { GiReceiveMoney } from "react-icons/gi";
import { Button, Checkbox } from "@material-ui/core";
import { Autorenew, ThumbUp } from "@material-ui/icons";
import { RiRefundFill } from "react-icons/ri";

import Map from "./Common/Map";
import Normal from "./Common/Normal";
import WithActions from "./Common/WithActions";
import Expendable from "./Common/Expendable";
import Message from "./Common/Message";
import DeliveryData from "./DeliveryData";
import ReviewCard from "../../Reviews/ReviewCard";
import Expandable from "../../../components/UI/Expandable";
import { ACTIVITY_TYPE, activityCustomDate } from "../../../utilities";
import Collapsible from "../../../components/UI/Collapsible";

export const icons = {
  [ACTIVITY_TYPE.ORDER_PLACED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <BiFile
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_ORDER]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.SUBMIT_SPECS]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(224 231 255)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(129 140 248)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.SPECS_SUBMITTED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(224 231 255)" }}
        className="rounded-circle"
      >
        <BiPencil
          style={{ color: "rgb(129 140 248)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.MORE_SPECS_SUBMITTED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(224 231 255)" }}
        className="rounded-circle"
      >
        <BiPencil
          style={{ color: "rgb(129 140 248)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PRODUCT_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.APPOINTMENT_BOOKED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <BiFile
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.APPOINTMENT_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.NEW_APPOINTMENT_BOOKED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <BiFile
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PICKUP_ADDRESS]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ADDRESS_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.STORE_MAP]: {
    icon: (
      <div
        style={{
          backgroundColor: "rgb(254 226 226)",
          height: "40px",
          width: "44px",
        }}
        className="d-flex justify-content-center align-items-center rounded-circle"
      >
        <FaMapMarkerAlt
          style={{ color: "rgb(248 113 113)", height: "24px", width: "24px" }}
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.NEW_ADDONS]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <TbApps
          style={{ color: "rgb(59 130 246)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PRODUCT]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.DELIVERY_DATE_INFO]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.NEW_PICKUP_DATE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdOutlineDateRange
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.DELIVER_ORDER]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_DELIVERED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <BiRocket style={{ color: "#019B5D" }} className="activity-icon p-2" />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_DELIVERY]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.GIVE_VENDOR_TIP]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <TbPigMoney
          style={{ color: "rgb(96 165 250)", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.GAVE_VENDOR_TIP]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <GiReceiveMoney
          style={{ color: "rgb(96 165 250)", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.AMOUNT_REFUNDED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <RiRefundFill
          style={{ color: "rgb(96 165 250)", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.DELIVERY_PRICE_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.SELECT_PICKUP_DATE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdOutlineDateRange
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PICKUP_DATE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PICKUP_DATE_PROVIDED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PICKUP]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.REVIEW_SUBMITTED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_CANCEL_REQUEST]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.WITHDRAW_CANCELLATION]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <FcOk
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_CANCEL_REQUEST_APPROVED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_CANCEL_REQUEST_DENIED]: {
    icon: (
      <div style={{ backgroundColor: "#fecaca" }} className="rounded-circle">
        <MdPendingActions
          style={{ color: "#dc3545", padding: 8 }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CUSTOMER_CANCELLATION_CONFIRMATION]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: 8 }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_CANCELLED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdSchedule
          style={{ color: "rgb(59 130 246)", padding: 8 }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PRODUCT_REPOST_MESSAGE]: {
    icon: (
      <div style={{ backgroundColor: "#fecaca" }} className="rounded-circle">
        <Autorenew
          style={{ color: "#dc3545", padding: 8 }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.THANK_YOU_MESSAGE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FaHandshake
          style={{ color: "#019B5D", padding: 8 }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.AGREED_REFUND_POLICY]: {
    icon: (
      <div style={{ backgroundColor: "lightgray" }} className="rounded-circle">
        <IoMdCheckmarkCircle
          style={{ stroke: "gray", fill: "gray" }}
          className="activity-icon"
        />
      </div>
    ),
  },
};

export const CustomizedActivity = ({
  forTheClient,
  forTheVendor,
  orderDetails,
  activity,
  product,
  agreedRefundPolicy,
}) => {
  const { type, client, forClient, vendor, forVendor, sender, forAdmin } =
    activity;

  if (!type) return <div></div>;

  if (type === ACTIVITY_TYPE.ORDER_PLACED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } placed an order`}
        />
      );
    else if (forClient && forTheClient)
      return <Normal {...activity} text={`Your order is placed`} />;
    else return <div></div>;
  } else if (type?.split("-")?.[0] === ACTIVITY_TYPE.CONFIRM_ORDER) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Customer ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } has not approved product yet. please <span class='text-danger'>DO NOT START</span> the order.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text={"Please approve your product, so we can process your order"}
          actionType="confirmOrder"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.SPECS_SUBMITTED) {
    if (forVendor && forTheVendor)
      return (
        <Expendable
          {...activity}
          text={`${client?.firstName ?? ""} submitted the specs`}
        />
      );
    else if (forClient && forTheClient)
      return <Expendable {...activity} text="You submitted the specs" />;
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.MORE_SPECS_SUBMITTED) {
    if (forVendor && forTheVendor)
      return (
        <Expendable
          {...activity}
          text={`${client?.firstName ?? ""} submitted more the specs`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Expendable {...activity} text="You have submitted more the specs" />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ORDER_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } approved the product, please <span style="color:#019B5D">START PROCESSING</span> the order.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`You have approved the product on: <br/> ${activityCustomDate(
            activity?.createdAt
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PRODUCT_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } approved the product, please <span style="color:#019B5D">START PROCESSING</span> the order.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`You have approved the product on: <br/> ${activityCustomDate(
            activity?.createdAt
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.DELIVERY_DATE_INFO) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          text={
            orderDetails?.pickupChoice?.mode.includes("Pickup")
              ? `The customer ${client?.firstName ?? ""} ${
                  client?.lastName ?? ""
                } is waiting for you to confirm the pickup date`
              : `You will deliver the product on: <br> ${activityCustomDate(
                  orderDetails?.deliveryDate,
                  true
                )}`
          }
          actionType={
            orderDetails?.pickupChoice?.mode.includes("Pickup") &&
            "confirmPickupDate"
          }
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={
            orderDetails?.pickupChoice?.mode.includes("Pickup")
              ? `Please wait for ${vendor?.firstName} at ${vendor?.storeName} to confirm pickup date`
              : `Your product will be ready for delivery on: <br/> ${activityCustomDate(
                  orderDetails?.deliveryDate,
                  true
                )}`
          }
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PICKUP_DATE_INFO) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`The customer ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } will pickup the product on: <br> ${activityCustomDate(
            orderDetails?.pickupDate
          )}`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your product will be ready for pickup/delivery on: <br/> ${activityCustomDate(
            orderDetails?.pickupDate
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.APPOINTMENT_BOOKED) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } has scheduled an appointment to visit your store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
          actionType="confirmAppointment"
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`You have scheduled an appointment to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.APPOINTMENT_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`You have confirmed ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          }'s appointment to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        >
          <div className="flex gap-2 items-center mt-2">
            <b>Address:</b>
            <div className="font-italic">
              {orderDetails?.owner?.storeAddress}
            </div>
          </div>
          {activity?.status === ACTIVITY_TYPE.ADDRESS_CONFIRMED ? (
            <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Address Confirmed
            </div>
          ) : (
            <div className="flex gap-3 mt-2">
              <Button className="btn btn-success text-white">
                Confirm Pickup Address
              </Button>
              <Button className="btn btn-warning text-white">
                Update Store Address
              </Button>
            </div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your vendor has confirmed your appointment to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_PICKUP_ADDRESS) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Please confirm the adddress for ${client?.firstName ?? ""} ${
            client?.lastName
          } to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        >
          <div className="d-flex gap-2 flex-wrap align-items-center mt-2">
            <b>Address:</b>
            <div className="font-italic">
              {orderDetails?.owner?.storeAddress}
            </div>
          </div>
          {activity?.status === ACTIVITY_TYPE.ADDRESS_CONFIRMED ? (
            <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Address Confirmed
            </div>
          ) : (
            <div className="d-flex flex-wrap gap-4 mt-2">
              <Button disabled className="bg-primary text-white px-3">
                Confirm Pickup Address
              </Button>
              <Button disabled className="bg-warning text-white px-3">
                Update Store Address
              </Button>
            </div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your vendor has scheduled you a new appointment to visit the store on: <br/> <span class="bg-yellow-400"> ${activityCustomDate(
            activity?.appointmentDate
          )} </span>`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ADDRESS_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`You have confirmed the address for the customer to visit the store on:  <br/> <span class="bg-yellow-400"> ${activityCustomDate(
            activity?.appointmentDate
          )} </span>`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.NEW_APPOINTMENT_BOOKED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`You have scheduled new appointment for ${
            client?.firstName ?? ""
          } ${
            client?.lastName ?? ""
          } to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          client={client}
          text={`We are sorry, your appointment date is not available.`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.STORE_MAP) {
    if (forClient && forTheClient)
      return (
        <Map
          {...activity}
          text={`Click on the map to get directions to the store`}
          product={product}
          order={orderDetails}
        />
      );
    return <div></div>;
  } else if (type === ACTIVITY_TYPE.NEW_ADDONS) {
    if (forVendor && forTheVendor)
      return (
        <Expendable
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } has added services`}
          orderDetails={orderDetails}
          forVendor={forTheVendor}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Expendable
          {...activity}
          text={`You have added services`}
          orderDetails={orderDetails}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_PRODUCT) {
    if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text="Please approve your product once you visit the store"
          actionType="confirmProduct"
        />
      );

    return <div></div>;
  } else if (type === ACTIVITY_TYPE.NEW_PICKUP_DATE) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`The customer ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } will pickup the product on: <br> ${activityCustomDate(
            orderDetails?.pickupDate
          )} </br>`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your product will be ready for pickup/delivery on: <br> ${activityCustomDate(
            orderDetails?.pickupDate
          )} </br>`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.DELIVER_ORDER) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          text={`Customer ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } is waiting for you to deliver the order`}
          actionType="deliverOrder"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ORDER_DELIVERED) {
    if (forVendor && forTheVendor)
      return (
        <Normal {...activity} text="You have delivered the order">
          <DeliveryData isVendor={true} orderDetails={orderDetails} />
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text="Your order has been delivered successfully."
          actionType="acceptDelivery"
        >
          <DeliveryData isClient={true} orderDetails={orderDetails} />
        </WithActions>
      );
    else return <div></div>;
  } else if (type.split("-")?.[0] === ACTIVITY_TYPE.CONFIRM_DELIVERY) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          text="Have you delivered the order?"
          textAfterDate={activity?.confirmDeliveryAction}
          actionType="confirmDelivery"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.GIVE_VENDOR_TIP) {
    if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text="Show your gratitude by leaving a tip for us."
          actionType="tips"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.GAVE_VENDOR_TIP) {
    if (forClient && forTheClient)
      return <Normal {...activity} text="You left tips for us." />;
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.AMOUNT_REFUNDED) {
    return <Normal {...activity} text="Order amount refunded" />;
  } else if (type.split("-")?.[0] === ACTIVITY_TYPE.NEW_MESSAGE) {
    if (forAdmin) {
      if (forVendor && forTheVendor)
        return (
          <Message
            {...activity}
            orderDetails={orderDetails}
            name={`${sender?.firstName} ${sender?.lastName}`}
            profilePic={sender?.profilePic}
          />
        );
      else if (forClient && forTheClient)
        return (
          <Message
            {...activity}
            orderDetails={orderDetails}
            name={`${sender?.firstName} ${sender?.lastName}`}
            profilePic={sender?.profilePic}
          />
        );
      else return <></>;
    }

    if (forVendor && forTheVendor)
      return (
        <Message
          {...activity}
          orderDetails={orderDetails}
          profilePic={vendor?.profilePic}
          name={`${vendor?.firstName} ${vendor?.lastName}`}
        />
      );
    else if (forTheClient)
      return (
        <Message
          {...activity}
          orderDetails={orderDetails}
          profilePic={client?.profilePic}
          name={`${client?.firstName} ${client?.lastName}`}
        />
      );

    if (forClient && forTheClient)
      return (
        <Message
          {...activity}
          orderDetails={orderDetails}
          profilePic={client?.profilePic}
          name={`${client?.firstName} ${client?.lastName}`}
        />
      );
    else if (forTheVendor)
      return (
        <Message
          {...activity}
          orderDetails={orderDetails}
          profilePic={vendor?.profilePic}
          name={`${vendor?.firstName} ${vendor?.lastName}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE) {
    if (forVendor && forTheVendor)
      return <Normal {...activity} text="Please confirm delivery option." />;
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.DELIVERY_PRICE_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text="<span>Thank You for confirming the delivery option.</span></br> <span>We are sending the offer to the buyer.</span> </br> <span>Please wait for buyer response. </span>"
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text="<span>Vendor has provided your delivery option.</span></br> <span>Please accept and confirm </span>"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.SELECT_PICKUP_DATE) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Please wait for ${client?.firstName} to select pickup date`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text="<span>Please select pickup date</span>"
          actionType="confirmPickupDate"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_PICKUP_DATE) {
    if (forVendor && forTheVendor)
      return (
        <Normal {...activity} text="Please confirm pickup date">
          {activity?.status === ACTIVITY_TYPE.PICKUP_DATE_PROVIDED ? (
            <div className="py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Provided new pickup date options
            </div>
          ) : activity?.status === ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED ? (
            <div className="py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Pickup Date Confirmed
            </div>
          ) : (
            <div></div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Please wait for ${vendor?.firstName} at ${vendor?.storeName} to confirm pickup date`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PICKUP_DATE_PROVIDED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Please wait for ${client?.firstName} to select new pickup date`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`${vendor.firstName} at ${vendor?.storeName} has provided new pickup dates for you, <br/> Please select new pickup date`}
        >
          {activity?.status === ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED ? (
            <div className="py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Pickup Date Confirmed
            </div>
          ) : (
            <div></div>
          )}
        </Normal>
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${vendor?.firstName} confirmed order pickup for ${
            client.firstName
          } ${
            client.lastName
          } on: <br/> <b style="color:#60a5fa;font-style:italic;">${activityCustomDate(
            orderDetails?.pickupDate
          )}</b>.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`${vendor?.firstName} confirmed order pickup for ${
            client.firstName
          } ${
            client.lastName
          } on: <br/> <b style="color:#60a5fa;font-style:italic;">${activityCustomDate(
            orderDetails?.pickupDate
          )}</b>.`}
        />
      );
    else return <div></div>;
  } else if (type.split("-")?.[0] === ACTIVITY_TYPE.CONFIRM_PICKUP) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Did ${client?.firstName} ${client?.lastName} pickup the order?`}
          textAfterDate={activity?.vendorPickupAction}
        >
          {activity?.status === ACTIVITY_TYPE.PICKUP_CONFIRMED &&
          activity?.vendorPickupAction ? (
            activity?.vendorPickupAction === "no" ? (
              ""
            ) : (
              <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
                Thank you for confirming order pickup.
              </div>
            )
          ) : (
            <div className="flex gap-3">
              <Button disabled className="btn btn-primary text-white w-24">
                Yes
              </Button>
              <Button disabled className="btn btn-danger text-white w-24">
                No
              </Button>
            </div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text="Did you pickup the order?"
          textAfterDate={activity?.clientPickupAction}
        >
          {activity?.status === ACTIVITY_TYPE.PICKUP_CONFIRMED &&
          activity?.clientPickupAction ? (
            activity?.clientPickupAction === "no" ? (
              ""
            ) : (
              <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
                Thank you for confirming order pickup
              </div>
            )
          ) : (
            <div className="flex gap-3">
              <Button disabled className="bg-primary text-white w-24">
                Yes
              </Button>
              <Button disabled className="bg-danger text-white w-24">
                No
              </Button>
            </div>
          )}
        </Normal>
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.REVIEW_SUBMITTED) {
    return (
      <Normal
        {...activity}
        text={`Your review has been submitted. </br>Thank you!`}
      >
        {forVendor && forTheVendor ? (
          <Expandable text={orderDetails?.orderDelivery?.message} />
        ) : forClient && forTheClient ? (
          <ReviewCard
            activity={true}
            item={{
              ...orderDetails?.review,
              orderItem: orderDetails,
              clientInfo: activity?.client,
            }}
          />
        ) : (
          <></>
        )}
      </Normal>
    );
  } else if (type === ACTIVITY_TYPE.ORDER_CANCEL_REQUEST) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Your customer ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } is requesting to cancel this order.`}
        />
      );
    else if (forClient && forTheClient) {
      const cancellationFee = (25 / 100) * orderDetails?.totalPrice ?? 0;
      return (
        <Normal {...activity} text={`Your cancellation request is pending.`}>
          <div className="mt-2 detail-wrapper">
            <label>Reason for Cancelling:</label>
            <p
              className="message"
              dangerouslySetInnerHTML={{
                __html: orderDetails?.cancelRequest?.reason,
              }}
            ></p>
            <Collapsible
              action={
                <Button className="bg-primary text-white px-3">
                  Cancellation Agreement
                </Button>
              }
              defaultOpen={true}
            >
              <div className="d-flex gap-4 align-items-center">
                <Checkbox checked={true} size="medium" color="primary" />
                <p className="message m-0">Refund Timeline:</p>
              </div>
              <p className="cancellation-info">
                Refunds may take 3–10 business days to process, <br /> depending
                on your payment provider.
              </p>
              <div className="d-flex gap-4 align-items-center">
                <Checkbox checked={true} size="medium" color="primary" />
                <p className="message m-0">Cancellation Fee:</p>
              </div>
              <p className="cancellation-info">
                A cancellation fee of ${cancellationFee} will be charged to your
                account.
              </p>
              <br />
              <Collapsible
                action={
                  <span className="message cursor-pointer">
                    Why Is There a Fee? <BiSolidChevronDown />
                  </span>
                }
              >
                <p className="cancellation-info">
                  This fee covers the costs of processing your order and
                  restocking items.
                </p>
              </Collapsible>
              <div className="text-center">
                Agreed <ThumbUp className="ml-2" />
              </div>
            </Collapsible>
          </div>
        </Normal>
      );
    } else return <div></div>;
  } else if (type === ACTIVITY_TYPE.WITHDRAW_CANCELLATION) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } has withdrawn the cancellation request.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`You have withdrawn the cancellation request.`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ORDER_CANCEL_REQUEST_APPROVED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Cancellation request has been approved.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your cancellation request is being reviewed.`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ORDER_CANCEL_REQUEST_DENIED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`We will contact customer and let you know <br> __Order Cancellation Pending__`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CUSTOMER_CANCELLATION_CONFIRMATION) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Wait for ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } to Confirm, Order Cancellation Request <br> __Thank you__`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text={`Please Confirm, Order Cancellation Request <br> __Thank you__`}
          actionType="confirmCancellationRequest"
          orderDetails={orderDetails}
        />
      );
  } else if (type === ACTIVITY_TYPE.ORDER_CANCELLED) {
    const confirmedBy = orderDetails?.cancelRequest?.confirmedBy;
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Order Cancelled Successfully By ${confirmedBy?.firstName} ${confirmedBy?.lastName} <br> __Thank you__`}
        />
      );
    else if (forClient && forTheClient)
      return <Normal {...activity} text={`Your refund is being processed`} />;
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PRODUCT_REPOST_MESSAGE) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Please feel free to Repost your product.`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.THANK_YOU_MESSAGE) {
    return <Normal {...activity} text={`Thank you for your business!`} />;
  } else if (type === ACTIVITY_TYPE.AGREED_REFUND_POLICY) {
    if (forVendor && forTheVendor) {
      return (
        <Normal
          {...activity}
          text={`${client?.firstName} ${client?.lastName} have agreed to our No refund policy`}
        />
      );
    } else if (forClient && forTheClient) {
      return (
        <Normal
          {...activity}
          text={<Text onClick={agreedRefundPolicy} />}
        ></Normal>
      );
    }
  } else {
    return <div></div>;
  }
};

export default CustomizedActivity;

const Text = ({ onClick }) => {
  return (
    <p className="detail">
      <span class="underline cursor-pointer" onClick={onClick}>
        You have agreed
      </span>{" "}
      to our No refund policy
    </p>
  );
};
