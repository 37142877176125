import * as types from "./../types/product";

const initialState = {
  products: [],
  totalProudct: 0,
  trashProducts: [],
  totalTrashProduct: 0,
  loading: false,
  error: "",
  formData: null,
  addProductLoading: false,
  vendorProducts: [],
  totalVendorProduct: 0,
  productInfo: null,
};

const productReducers = (state = initialState, actions) => {
  switch (actions.type) {
    case types.LIST_PRODUCT_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
      };
    }
    case types.ADD_UPDATE_PRODUCT_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
        addProductLoading: false,
      };
    }
    case types.GET_PRODUCTS:
      return {
        ...state,
        loading: true,
        trashProducts: [],
        products: [],
      };
    case types.GET_PRODUCTS_SUCCESS: {
      if (actions.deleted === "true") {
        return {
          ...state,
          loading: false,
          trashProducts: actions.data,
          totalTrashProduct: actions.total,
        };
      } else {
        return {
          ...state,
          products: actions.data,
          totalProduct: actions.total,
          loading: false,
        };
      }
    }
    case types.ADD_TO_STORE_SUCCESS: {
      const products = [...state.products];
      const index = products.findIndex((e) => e._id === actions.data._id);
      products[index].isPublish = actions.data.isPublish;
      return { ...state, products, loading: false };
    }

    case types.ADD_PRODUCT_VIEW_SUCCESS: {
      const products = [...state.products];
      const index = products.findIndex((e) => e._id === actions.data._id);
      products[index].views = actions.data.views;
      return { ...state, products, loading: false };
    }
    case types.DELETE_PRODUCT:
    case types.TRASH_PRODUCT: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.DELETE_PRODUCT_SUCCESS:
    case types.TRASH_PRODUCT_SUCCESS: {
      const payload = actions.payload;
      if (payload.deleted) {
        const products = state?.products ?? [];
        const filteredProducts = products.filter((e) => e._id !== payload?._id);
        return (state = {
          ...state,
          products: filteredProducts,
          loading: false,
        });
      } else if (!payload.deleted) {
        const trashProducts = state?.trashProducts ?? [];
        const filteredProducts = trashProducts.filter(
          (e) => e._id !== payload?._id
        );
        return (state = {
          ...state,
          trashProducts: filteredProducts,
          loading: false,
        });
      }
    }
    case types.CHANGE_STATUS: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.CHANGE_STATUS_SUCCESS: {
      const { id, status } = actions.payload ?? {};
      const products = state?.products ?? [];
      const filteredProducts = products.map((e) => {
        if (e._id === id) {
          return { ...e, status };
        } else {
          return e;
        }
      });
      return (state = {
        ...state,
        products: filteredProducts,
        loading: false,
      });
    }
    case types.ADD_PRODUCT: {
      return {
        ...state,
        addProductLoading: true,
      };
    }

    case types.ADD_PRODUCT_SUCCESS: {
      const products = [...state.products, actions.data];
      return {
        ...state,
        products,
        addProductLoading: false,
      };
    }
    case types.UPDATE_PRODUCT: {
      return {
        ...state,
        addProductLoading: true,
      };
    }
    case types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        addProductLoading: false,
      };
    case types.ADD_FLAG_SUCCESS: {
      const payload = actions.payload;
      const filteredProducts = state.products.map((ele) => {
        if (ele?._id == payload?._id) {
          return { ...ele, ...payload };
        } else {
          return ele;
        }
      });
      return {
        ...state,
        products: filteredProducts,
      };
    }
    case types.SAVE_FORM_DATA:
      return { ...state, formData: actions.payload };
    case types.RESET_PRODUCT: {
      return initialState;
    }
    case types.GET_PRODUCT_INFO: {
      return { ...state, loading: false, productInfo: null };
    }
    case types.GET_PRODUCT_INFO_SUCCESS: {
      return { ...state, loading: false, productInfo: actions.payload };
    }
    case types.GET_VENDOR_PRODUCTS: {
      return {
        ...state,
        loading: false,
        vendorProducts: [],
        totalVendorProduct: 0,
      };
    }
    case types.GET_VENDOR_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorProducts: actions.data,
        totalVendorProduct: actions.total,
      };
    }
    case types.DELETE_ALL_SUCCESS: {
      const payload = actions.payload;
      if (payload?.trash) {
        const filtered = state.products?.filter(
          (ele) => !payload.ids.includes(ele?._id)
        );
        return {
          ...state,
          loading: false,
          products: filtered,
          totalProduct: filtered?.length,
        };
      } else {
        const filtered = state.trashProducts?.filter(
          (ele) => !payload.ids.includes(ele?._id)
        );
        return {
          ...state,
          loading: false,
          trashProducts: filtered,
          totalTrashProduct: filtered?.length,
        };
      }
    }
    case types.PRODUCT_FAILED: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default productReducers;
