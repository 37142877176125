import React, { useState } from "react";
import moment from "moment";
import { DateRange as Picker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DefinedRange from "./DefinedRange";

const DateRange = ({ state, onChange, onClose }) => {
  const [error, setError] = useState("");
  const onChangeDate = (item) => {
    const { startDate, endDate } =
      item.range1?.selection ?? item.range1 ?? item.selection ?? {};

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.month() !== end.month() || start.year() !== end.year()) {
      setError("Only 1 Month Allowed");
      return;
    }

    setError("");
    onChange({
      startDate,
      endDate,
      period: null,
      isCustom: true,
    });
    onClose();
  };

  return (
    <div className="d-flex flex-column gap-8">
      <DefinedRange onChange={onChange} onClose={onClose} />
      {error && (
        <span className="text-md text-center text-danger">{error}</span>
      )}
      <Picker
        ranges={[state]}
        onChange={onChangeDate}
        moveRangeOnFirstSelection={false}
        maxDate={new Date()}
      />
    </div>
  );
};

export default DateRange;
