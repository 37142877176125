import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import UserList from "./UserList";
import Paginator from "../Pagination";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import Header from "./../../components/Headers/DefaultHeader";
import SearchField from "./../../components/SearchFields";
import * as userActions from "./../../store/actions/userActions";
import { get } from "../../store/lib/Api";
import { scrollTo } from "../../utilities";
import "../User/User.css";
import { useHistory } from "../../contexts/AppProvider";

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const { users, loading, totalUser } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      userActions.getUsers(token, { page, deleted: "false", user: "true" })
    );
  }, []);

  useEffect(() => {
    const id = history.location.search?.split("=")?.[1];
    const index = users.findIndex((user) => user._id === id);
    if (index !== -1) {
      const page = Math.floor(index / 10) + 1;
      setPage(page);
    }
    setTimeout(() => {
      scrollTo(id, 150);
    }, 1000);
  }, []);

  useEffect(() => {
    setUserList(users);
  }, [users]);

  const nameSearch = (value) => {
    if (value) {
      let searchText = value?.toLowerCase();
      const filter = users.filter((user) => {
        return (
          user.email?.toLowerCase()?.includes(searchText) ||
          user.firstName?.toLowerCase()?.includes(searchText) ||
          user.lastName?.toLowerCase()?.includes(searchText)
        );
      });
      setUserList(filter);
    } else {
      setUserList(users);
    }
  };

  const onActivateUser = (id) => {
    dispatch(userActions.activateUser({ id, token, option: "user" }));
  };

  const onPageChange = (page) => {
    setPage(page);
    dispatch(
      userActions.getUsers(token, { page, deleted: "false", user: "true" })
    );
  };

  const onDelete = (id) => {
    setId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    dispatch(
      userActions.trashUser({ id, payload: { deleted: "true" }, token })
    );
    setId("");
    setShowDeleteModal(false);
  };

  const onContact = (email) => {
    get(`/users/hubspotContact?email=${email}`, {
      Authorization: `Bearer ${token}`,
    }).then((data) => {
      if (data?.contactId) {
        window.open(
          `https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_ID}/contact/${data?.contactId}`,
          "_blank"
        );
      }
    });
  };

  const onClose = () => {
    setId("");
    setShowDeleteModal(false);
  };

  const startIndex = (page - 1) * 10;
  const endIndex = startIndex + 10;

  return (
    <>
      {loading && <Loader />}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Users</h3>
              </CardHeader>
              <div className="HeaderWrapper">
                <SearchField onChange={nameSearch} />
              </div>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">NAME</th>
                    <th scope="col">Purchase Orders</th>
                    <th scope="col">Total $$ Spents</th>
                    <th scope="col">ACTIONS</th>
                  </tr>
                </thead>
                <UserList
                  token={token}
                  users={userList}
                  activateUser={onActivateUser}
                  start={startIndex}
                  end={endIndex}
                  onDelete={onDelete}
                  onContact={onContact}
                />
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    page={page}
                    perPage={10}
                    total={totalUser}
                    onPageChange={onPageChange}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        {showDeleteModal && (
          <ConfirmationModal
            title="Are you sure you want to delete this user?"
            onClose={onClose}
            onConfirm={confirmDelete}
          />
        )}
      </Container>
    </>
  );
};

export default Users;
