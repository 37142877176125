import * as types from "./../types/product";

export const getProducts = (token, query) => {
  return {
    type: types.GET_PRODUCTS,
    token,
    query,
  };
};
export const getProductsSuccess = (payload) => {
  return {
    type: types.GET_PRODUCTS_SUCCESS,
    ...payload,
  };
};

export const addView = (id, token) => {
  return {
    type: types.ADD_PRODUCT_VIEW,
    payload: { id, token },
  };
};

export const addViewSuccess = (data) => {
  return {
    type: types.ADD_PRODUCT_VIEW_SUCCESS,
    data,
  };
};
export const addToStore = (id, token) => {
  return {
    type: types.ADD_TO_STORE,
    payload: { id, token },
  };
};
export const addToStoreSuccess = (data) => {
  return {
    type: types.ADD_TO_STORE_SUCCESS,
    data,
  };
};
export const deleteProduct = (id, token) => {
  return {
    type: types.DELETE_PRODUCT,
    payload: { id, token },
  };
};
export const deleteProductSuccess = (payload) => {
  return {
    type: types.DELETE_PRODUCT_SUCCESS,
    payload,
  };
};
export const listProductFailed = (message) => {
  return {
    type: types.LIST_PRODUCT_FAILED,
    message,
  };
};

export const addUpdateProductFailed = (message) => {
  return {
    type: types.ADD_UPDATE_PRODUCT_FAILED,
    message,
  };
};
export const addProduct = (product, token) => {
  return {
    type: types.ADD_PRODUCT,
    payload: { product, token },
  };
};
export const addProductSuccess = (data) => {
  return {
    type: types.ADD_PRODUCT_SUCCESS,
    data,
  };
};

export const updateProduct = (id, product, token) => {
  return {
    type: types.UPDATE_PRODUCT,
    payload: { id, product, token },
  };
};
export const updateProductSuccess = (data) => {
  return {
    type: types.UPDATE_PRODUCT_SUCCESS,
    data,
  };
};

export const addFlag = (id, payload, token) => ({
  type: types.ADD_FLAG,
  id,
  payload,
  token,
});

export const addFlagSuccess = (payload) => ({
  type: types.ADD_FLAG_SUCCESS,
  payload,
});

export const changeStatus = (id, status, token) => {
  return {
    type: types.CHANGE_STATUS,
    id,
    status,
    token,
  };
};

export const changeStatusSuccess = (payload) => {
  return {
    type: types.CHANGE_STATUS_SUCCESS,
    payload,
  };
};

export const trashProduct = (id, payload, token) => {
  return {
    type: types.TRASH_PRODUCT,
    id,
    payload,
    token,
  };
};

export const trashProductSuccess = (payload) => {
  return {
    type: types.TRASH_PRODUCT_SUCCESS,
    payload,
  };
};

export const resetProduct = () => ({
  type: types.RESET_PRODUCT,
});

export const saveFormData = (formData) => ({
  type: types.SAVE_FORM_DATA,
  payload: formData,
});

export const getProductInfo = (id, token) => ({
  type: types.GET_PRODUCT_INFO,
  id,
  token,
});

export const getProductInfoSuccess = (payload) => ({
  type: types.GET_PRODUCT_INFO_SUCCESS,
  payload,
});

export const getVendorProducts = (payload) => ({
  type: types.GET_VENDOR_PRODUCTS,
  ...payload,
});

export const getVendorProductsSuccess = (payload) => ({
  type: types.GET_VENDOR_PRODUCTS_SUCCESS,
  ...payload,
});

export const deleteAllProducts = (payload) => ({
  type: types.DELETE_ALL,
  ...payload,
});

export const deleteAllProductsSuccess = (payload) => ({
  type: types.DELETE_ALL_SUCCESS,
  payload,
});

export const productFailed = () => ({
  type: types.PRODUCT_FAILED,
});
