import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { get } from "../../store/lib/Api";
import useScreenResolution from "../../hooks/useScreenResolution";

function RefundPolicyModal({ onClose }) {
  const { isMobile } = useScreenResolution();
  const [content, setContent] = useState("");

  useEffect(() => {
    get("/footerMenu/content?slug=terms-of-use", {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    }).then((res) => {
      if (res.data.content?.content) {
        setContent(res.data.content?.content);
      }
    });
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" open={true} onClose={onClose}>
      <div className="position-relative w-100">
        <Close className="close-btn" onClick={onClose} />
      </div>
      <DialogContent dividers>
        <div className="text-md font-semibold text-center">Refund Policy</div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
      <DialogActions className="flex justify-center">
        <Button className="bg-dark px-6 text-white" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RefundPolicyModal;
