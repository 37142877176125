import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaEdit, FaTrash, FaCheck, FaWindowClose } from "react-icons/fa";
import { Chip, Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import "./style.css";
import * as Const from "../../../utilities";
import {
  getProductInfo,
  getProductInfoSuccess,
} from "../../../store/actions/productActions";
import { useHistory } from "../../../contexts/AppProvider";
import placeholder from "../../../assets/img/Placeholder.png";

const ProductModal = ({
  open,
  onClose: onCloseModal,
  productId,
  product,
  handleEdit,
  handleDelete,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { productInfo } = useSelector((state) => state.product);
  const productData = productInfo ?? product;

  useEffect(() => {
    if (productId) {
      dispatch(getProductInfo(productId));
    }
  }, [productId]);

  const onClose = () => {
    onCloseModal();
    dispatch(getProductInfoSuccess(null));
    if (productId) {
      history.push(history.location.pathname);
    }
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open || (productId && !!productData)}
      onClose={onClose}
      aria-hidden={false}
      aria-label="Preview Modal"
    >
      <DialogTitle>
        <Close onClick={onClose} className="close-btn" />
      </DialogTitle>
      <DialogContent className="pb-4">
        <Row>
          <Col sm="12" md="5">
            <div className="images">
              <img
                className="w-100 h-100 rounded"
                src={
                  productData?.productPic?.[0]?.url
                    ? `${productData?.productPic?.[0]?.url}`
                    : placeholder
                }
                alt=""
              />
            </div>
          </Col>
          <Col sm="12" md="7">
            <div className="product mt-2">
              <p>
                <em>
                  {productData && productData.sku
                    ? productData.sku
                    : "No keyword"}
                </em>{" "}
                &nbsp; &nbsp;
                <Chip
                  label={
                    Const.productStatusOptions.find(
                      (option) => option?.value == productData?.status
                    )?.label
                  }
                />
              </p>
              <div dangerouslySetInnerHTML={{ __html: productData?.name }} />
              <h2>
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "#fb6340",
                  }}
                >
                  ${productData?.price}
                  <br />
                </span>
                <span
                  style={{
                    color: "Green",
                  }}
                >
                  ${productData?.finalPrice}
                </span>
                &nbsp; &nbsp; <FaEye size={"17px"} />
                &nbsp;{productData?.views}{" "}
              </h2>
              {productData?.stock > 0 ? (
                <span className="inStock">
                  <FaCheck />
                  {productData?.stock} in stock
                </span>
              ) : (
                <span className="outStock">
                  <FaWindowClose />
                  {productData?.stock} in stock
                </span>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: productData?.description }}
              />
              <div className="buttons">
                <button
                  onClick={() => handleEdit(productData?._id)}
                  className="btn-sm btn-info editModal"
                >
                  Edit <FaEdit />
                </button>
                <button
                  onClick={() => handleDelete(productData)}
                  className="btn-sm btn-danger deleteModal"
                >
                  Delete <FaTrash />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default ProductModal;
