import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./../types/order";
import * as service from "./../services/order";
import * as actions from "./../actions/orderActions";
import { errorResponse, successResponse } from "./auth";
import { HeadersOrigin, get, post } from "../lib/Api";

function* getOrder({ token, query }) {
  try {
    const result = yield call(service.orders, token, query);
    yield put(actions.getOrderSuccess(result));
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* getMyOrder(action) {
  const { user } = action.payload;
  try {
    const result = yield call(service.myOrders, user);
    if (result.status === 200) {
      yield put(actions.getOrderSuccess(result.data.message));
    }
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* getOrderItemsSaga(action) {
  const { orderId, isVendor } = action.payload;
  try {
    const result = yield call(service.orderItmes, orderId, isVendor);
    if (result.data.success === true) {
      yield put(actions.getOrderItemsSuccess(result.data.message));
    }
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* deleteOrder(action) {
  const { id, token } = action.payload;
  try {
    const result = yield call(service.deleteOrder, id, token);
    if (result.status === 200) {
      yield put(actions.deleteOrderSuccess(id));
    }
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* getSingleOrder({ id, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const response = yield call(() => get(`/order/singleOrder/${id}`, headers));
    yield put(actions.getSingleOrderSuccess(response.data));
  } catch (err) {
    errorResponse(err);
    yield put(actions.orderFailed());
  }
}

function* orderRefund({ payload, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => post("/order/refund", payload, headers));
    yield put(actions.orderRefundSuccess(payload));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* userOrderRefund({ payload, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => post("/order/refund", payload, headers));
    yield put(actions.userOrderRefundSuccess(payload));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* vendorOrders({ vendorId, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() =>
      get(`/order/vendorOrders/${vendorId}`, headers)
    );
    yield put(actions.getVendorOrdersSuccess(result?.data));
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* userOrders({ userId, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => get(`/order/getorders/${userId}`, headers));
    yield put(actions.getUserOrdersSuccess(result?.data ?? []));
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* cancelOrderRequest({ payload, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() =>
      post(
        `/order/cancelRequest/cancel/${payload?._id}`,
        { ...payload },
        headers
      )
    );
    yield put(actions.cancelOrderSuccess(result?.data));
    window.location.reload();
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* approveCancelOrderRequest({ payload, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() =>
      post(`/order/cancelRequest/approve/${payload?._id}`, payload, headers)
    );
    yield put(actions.approveCancelOrderSuccess(result?.data));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* getReviews({ token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => get(`/users/review`, headers));
    yield put(actions.getReviewsSuccess(result?.data ?? []));
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* createActivity({ payload, token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() =>
      post(`/order/order-activity`, payload, headers)
    );
    yield put(actions.createActivitySuccess(result?.data ?? []));
    window.location.reload();
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

function* getCustomerReviews({ token }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  try {
    const result = yield call(() => get(`/users/review`, headers));
    yield put(actions.getCustomerReviewsSuccess(result?.data ?? []));
  } catch (error) {
    errorResponse(error);
    yield put(actions.orderFailed());
  }
}

export default function* orderWatcher() {
  yield takeLatest(types.GET_ORDER, getOrder);
  yield takeLatest(types.DELETE_ORDER, deleteOrder);
  yield takeLatest(types.GET_MY_ORDER, getMyOrder);
  yield takeLatest(types.GET_ORDER_ITEMS, getOrderItemsSaga);
  yield takeLatest(types.GET_SINGLE_ORDER, getSingleOrder);
  yield takeLatest(types.ORDER_REFUND, orderRefund);
  yield takeLatest(types.USER_ORDER_REFUND, userOrderRefund);
  yield takeLatest(types.GET_VENDOR_ORDERS, vendorOrders);
  yield takeLatest(types.GET_USER_ORDERS, userOrders);
  yield takeLatest(types.CANCEL_ORDER_REQUEST, cancelOrderRequest);
  yield takeLatest(
    types.APPROVE_CANCEL_ORDER_REQUEST,
    approveCancelOrderRequest
  );
  yield takeLatest(types.GET_REVIEWS, getReviews);
  yield takeLatest(types.CREATE_ORDER_ACTIVITY, createActivity);
  yield takeLatest(types.GET_CUSTOMER_REVIEWS, getCustomerReviews);
}
