import moment from "moment";

export const stoneremLogo =
  "https://res.cloudinary.com/dqsp9tfhi/image/upload/v1728845192/STN/SiteAssets/ctvfhzn6njbi9mb3fq48.png";

export const profilePlaceholder =
  "https://res.cloudinary.com/dqsp9tfhi/image/upload/v1736458708/STN/SiteAssets/whruqffrag6wqydxd3r5.png";

export const emojies = {
  crying:
    "https://res.cloudinary.com/dqsp9tfhi/image/upload/v1738301181/STN/SiteAssets/cxpwq14kfj0v7ex53ao6.png",
  heartSmiley:
    "https://res.cloudinary.com/dqsp9tfhi/image/upload/v1730991015/STN/SiteAssets/aus3eq2urs7gnfrdbxug.png",
};

export const colors = [
  { label: "Cream", value: "Cream" },
  { label: "Gold", value: "Gold" },
  { label: "Gray", value: "Gray" },
  { label: "Blue", value: "Blue" },
  { label: "Green", value: "Green" },
  { label: "Multi Color", value: "Multi Color" },
  { label: "Rustic", value: "Rustic" },
  { label: "White", value: "White" },
  { label: "Yellow", value: "Yellow" },
  { label: "Beige", value: "Beige" },
  { label: "Black", value: "Black" },
  { label: "Brown", value: "Brown" },
  { label: "Ivory", value: "Ivory" },
];

export const convertDate = (dt) => {
  const date = new Date(dt);
  return date.toDateString();
};

export const articleSelector = [
  { value: "published", label: "Published" },
  { value: "pending", label: "Pending" },
];

export const productStatusOptions = [
  { value: "pending", label: "Pending" },
  { value: "sold", label: "Sold" },
  { value: "approved", label: "Approved" },
  { value: "cancelled", label: "Cancelled" },
  { value: "removed", label: "Removed" },
];

export const roleTypes = [
  { label: "Admin", value: "admin" },
  { label: "Article Manager", value: "article-manager" },
  { label: "Product Manager", value: "product-manager" },
];

export const limitOptions = [
  { label: "25", value: "25" },
  { label: "50", value: "50" },
  { label: "55", value: "55" },
  { label: "100", value: "100" },
];

export const unitOptions = [
  { label: "Inches", value: "inch" },
  { label: "Centimeter", value: "cm" },
];

export const surfaceOptions = [
  { label: "Brushed", value: "Brushed" },
  { label: "Honed", value: "Honed" },
  { label: "Leather", value: "Leather" },
  { label: "Polished", value: "Polished" },
];

export const thicknessOptions = [
  { label: "3CM", value: "CM3" },
  { label: "2CM", value: "CM2" },
];

export const brandOptions = [
  // {
  //   label: "None",
  //   value: "",
  // },
  {
    label: "Granite",
    value: "Granite",
  },
  {
    label: "Quartz",
    value: "Quartz",
  },
  // {
  //   label: "Hanstone Quartz",
  //   value: "Hanstone Quartz",
  // },
  // {
  //   label: "Caesarstone Quartz",
  //   value: "Caesarstone Quartz",
  // },
  // {
  //   label: "Cambria Quartz",
  //   value: "Cambria Quartz",
  // },
  // {
  //   label: "Silestone Quartz",
  //   value: "Silestone Quartz",
  // },
  // {
  //   value: "Dekton Quartz",
  //   label: "Dekton Quartz",
  // },
  {
    label: "Soapstone",
    value: "Soapstone",
  },
  {
    label: "Marble",
    value: "Marble",
  },
  {
    label: "Quartzite",
    value: "Quartzite",
  },
  {
    label: "Porcelain",
    value: "Porcelain",
  },
  {
    label: "Dolomite",
    value: "Dolomite",
  },
  {
    label: "Onyx",
    value: "Onyx",
  },
  {
    label: "Nano Glass",
    value: "Nano Glass",
  },
  {
    label: "Travertine",
    value: "Travertine",
  },
  {
    label: "Gemstone",
    value: "Gemstone",
  },
  {
    label: "Limestone",
    value: "Limestone",
  },
  {
    label: "Slate",
    value: "Slate",
  },
];

export const quartzBrands = [
  {
    value: "Quartz",
    label: "Quartz",
  },
  {
    label: "Caesarstone Quartz",
    value: "Caesarstone Quartz",
  },
  {
    label: "Cambria Quartz",
    value: "Cambria Quartz",
  },
  {
    value: "Dekton Quartz",
    label: "Dekton Quartz",
  },
  {
    label: "Hanstone Quartz",
    value: "Hanstone Quartz",
  },
  {
    label: "Silestone Quartz",
    value: "Silestone Quartz",
  },
];

export const porcelainBrands = [];

export const OrderStatus = {
  STARTED: "STARTED",
  INPROGRESS: "INPROGRESS",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  REFUNDED: "REFUNDED",
};

export const allStatus = [
  {
    label: "Started",
    status: "STARTED",
    color: "#2563EB",
  },
  {
    label: "In Progress",
    status: "INPROGRESS",
    color: "#F97316",
  },
  {
    label: "Delivered",
    status: "DELIVERED",
    color: "#019B5D",
  },
  {
    label: "Cancelled",
    status: "CANCELLED",
    color: "#EF4444",
  },
  {
    label: "Refunded",
    status: "REFUNDED",
    color: "#6B7280",
  },
];

export const ACTIVITY_TYPE = {
  ORDER_PLACED: "ORDER_PLACED",
  CONFIRM_ORDER: "CONFIRM_ORDER",
  SUBMIT_SPECS: "SUBMIT_SPECS",
  NO_SPECS: "NO_SPECS",
  SPECS_SUBMITTED: "SPECS_SUBMITTED",
  MORE_SPECS_SUBMITTED: "MORE_SPECS_SUBMITTED",
  NEW_MESSAGE: "NEW_MESSAGE",
  ORDER_CONFIRMED: "ORDER_CONFIRMED",
  APPOINTMENT_BOOKED: "APPOINTMENT_BOOKED",
  APPOINTMENT_CONFIRMED: "APPOINTMENT_CONFIRMED",
  NEW_APPOINTMENT_BOOKED: "NEW_APPOINTMENT_BOOKED",
  STORE_MAP: "STORE_MAP",
  NEW_ADDONS: "NEW_ADDONS",
  CONFIRM_PRODUCT: "CONFIRM_PRODUCT",
  PRODUCT_CONFIRMED: "PRODUCT_CONFIRMED",
  ADDRESS_CONFIRMED: "ADDRESS_CONFIRMED",
  DELIVERY_DATE_INFO: "DELIVERY_DATE_INFO",
  NEW_PICKUP_DATE: "NEW_PICKUP_DATE",
  CONFIRM_PICKUP_ADDRESS: "CONFIRM_PICKUP_ADDRESS",
  GIVE_VENDOR_TIP: "GIVE_VENDOR_TIP",
  REVIEW_SUBMITTED: "REVIEW_SUBMITTED",
  GAVE_VENDOR_TIP: "GAVE_VENDOR_TIP",
  DELIVER_ORDER: "DELIVER_ORDER",
  ORDER_DELIVERED: "ORDER_DELIVERED",
  CONFIRM_DELIVERY: "CONFIRM_DELIVERY",
  DELIVERY_CONFIRMED: "DELIVERY_CONFIRMED",
  AMOUNT_REFUNDED: "AMOUNT_REFUNDED",
  CONFIRM_DELIVERY_PRICE: "CONFIRM_DELIVERY_PRICE",
  DELIVERY_PRICE_CONFIRMED: "DELIVERY_PRICE_CONFIRMED",
  SELECT_PICKUP_DATE: "SELECT_PICKUP_DATE",
  CONFIRM_PICKUP_DATE: "CONFIRM_PICKUP_DATE",
  PICKUP_DATE_PROVIDED: "PICKUP_DATE_PROVIDED",
  PICKUP_DATE_CONFIRMED: "PICKUP_DATE_CONFIRMED",
  CONFIRM_PICKUP: "CONFIRM_PICKUP",
  PICKUP_CONFIRMED: "PICKUP_CONFIRMED",
  ORDER_CANCEL_REQUEST: "ORDER_CANCEL_REQUEST",
  WITHDRAW_CANCELLATION: "WITHDRAW_CANCELLATION",
  ORDER_CANCEL_REQUEST_APPROVED: "ORDER_CANCEL_REQUEST_APPROVED",
  ORDER_CANCEL_REQUEST_DENIED: "ORDER_CANCEL_REQUEST_DENIED",
  CUSTOMER_CANCELLATION_CONFIRMATION: "CUSTOMER_CANCELLATION_CONFIRMATION",
  CUSTOMER_CANCELLATION_CONFIRMED: "CUSTOMER_CANCELLATION_CONFIRMED",
  ORDER_CANCELLED: "ORDER_CANCELLED",
  PRODUCT_REPOST_MESSAGE: "PRODUCT_REPOST_MESSAGE",
  THANK_YOU_MESSAGE: "THANK_YOU_MESSAGE",
  AGREED_REFUND_POLICY: "AGREED_REFUND_POLICY",
};

export const activityCustomDate = (date, noTime = false) => {
  return `${moment(date).format(
    "dddd"
  )} <span class="uppercase text-base">${moment(date).format(
    "MMM"
  )}</span> ${moment(date).format("DD, YYYY")} ${
    !noTime ? `at ${moment(date).format("hh:mm A")}` : ""
  }`;
};

export const objectToQueryParams = (obj = {}) => {
  const queryString = Object.keys(obj)
    .filter(
      (key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== ""
    )
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryString ? `?${queryString}` : "";
};

export const generateRandomNumber = (length) => {
  if (typeof length !== "number" || length <= 0 || !Number.isInteger(length)) {
    throw new Error("Length must be a positive integer");
  }

  let randomNumber = "";
  for (let i = 0; i < length; i++) {
    randomNumber += Math.floor(Math.random() * 10);
  }
  return randomNumber;
};

export const formatDate = (date, addYear) => {
  return moment(date).format(`dddd MM DD ${addYear ? "YYYY" : ""}, hh:mm A`);
};

export const saveFile = (urlFile, fileName) => {
  fetch(urlFile)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error("Error downloading the file:", error);
    });
};

export const customDate = (date) => {
  return `${moment(date).format(
    "dddd"
  )} <span class="uppercase text-base">${moment(date).format(
    "MMM"
  )}</span> ${moment(date).format("DD, YYYY")} at ${moment(date).format(
    "hh:mm A"
  )}`;
};

export const deliveryDate = (
  orderedDate,
  onlyDays = false,
  diff = false,
  extra,
  noTime
) => {
  const addons = extra?.addons ?? [];
  const getDate = (days) => {
    if (diff) {
      return `${days} Days`;
    } else if (onlyDays) {
      return days;
    } else {
      let current = moment(orderedDate);
      while (days > 0) {
        current.add(1, "days");
        if (current.isoWeekday() !== 6 && current.isoWeekday() !== 7) {
          days--;
        }
      }
      return activityCustomDate(current, noTime);
    }
  };

  const findAddon = addons.find((item) => item?.pickupChoiceDay);
  return getDate(extra?.pickupDays ?? findAddon?.pickupChoiceDay);
};

export const brandRelatedCategories = [
  "quartz",
  "quartz slab",
  "quartz slabs",
  "quartz countertop",
  "quartz countertops",
  "porcelain",
  "porcelain slab",
  "porcelain slabs",
  "porcelain countertop",
  "porcelain countertops",
];

// export const load = async (ffmpeg) => {
//   const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
//   await ffmpeg.load({
//     coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
//     wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
//   });
// };

// export const transcode = async (ffmpeg, file, mimeType) => {
//   if (mimeType === "image") {
//     await ffmpeg.writeFile(
//       `${file?.name}-input.jpg`,
//       await fetchFile(URL.createObjectURL(file))
//     );
//     await ffmpeg.exec([
//       "-i",
//       `${file?.name}-input.jpg`,
//       "-q:v",
//       "8",
//       `${file?.name}-output.jpg`,
//     ]);
//     const data = await ffmpeg.readFile(`${file?.name}-output.jpg`);

//     const compressedFile = new File([data.buffer], file?.name, {
//       type: "image/jpg",
//     });
//     return compressedFile;
//   } else {
//     await ffmpeg.writeFile(
//       "input.avi",
//       await fetchFile(URL.createObjectURL(file))
//     );
//     await ffmpeg.exec([
//       "-i",
//       "input.avi",
//       "-t",
//       "30",
//       "-c",
//       "copy",
//       "output.mp4",
//     ]);
//     const data = await ffmpeg.readFile("output.mp4");

//     const compressedFile = new File([data.buffer], file?.name, {
//       type: "video/mp4",
//     });
//     return compressedFile;
//   }
// };

export const generateThumbnail = async (ffmpeg) => {
  await ffmpeg.exec([
    "-i",
    "output.mp4",
    "-ss",
    "00:00:01",
    "-frames:v",
    "1",
    "thumbnail.png",
  ]);

  const data = await ffmpeg.readFile("thumbnail.png");
  const blob = new Blob([data.buffer], { type: "image/png" });
  return blob;
};

export const hanstoneProduct = {
  Antello: "Antello",
  Aurelia: "Aurelia",
  "Calacatta Mont": "Calacatta Mont",
  Eden: "Eden",
  "Elba White": "Elba White",
  "Le Blanc": "Le Blanc",
  Matterhorn: "Matterhorn",
  Odina: "Odina",
  Patagonia: "Patagonia",
  Ridge: "Ridge",
  Riviere: "Riviere",
  "Royale Blanc": "Royale Blanc",
  "Tahitian Cream": "Tahitian Cream",
  "Bianco Canvas": "Bianco Canvas",
  Tranquility: "Tranquility",
  "Calacatta Extra": "Calacatta Extra",
  Chantilly: "Chantilly",
  Strato: "Strato",
  "Swan Cotton": "Swan Cotton",
  "Aurora Snow": "Aurora Snow",
  Drift: "Drift",
  "Italian Waves": "Italian Waves",
  "Brava Marfil": "Brava Marfil",
  Paros: "Paros",
  "Specchio White": "Specchio White",
  Yorkville: "Yorkville",
  Whistler: "Whistler",
  Tofino: "Tofino",
  Soho: "Soho",
  "Calacatta Gold": "Calacatta Gold",
  Montauk: "Montauk",
  Empress: "Empress",
  "Calacatta Venato": "Calacatta Venato",
  Mercer: "Mercer",
  Aspen: "Aspen",
  Coast: "Coast",
  Serenity: "Serenity",
  Oceana: "Oceana",
  Fusion: "Fusion",
  "Indian Pearl": "Indian Pearl",
  "Rocky Shores": "Rocky Shores",
  Kindred: "Kindred",
  Bavaria: "Bavaria",
  Aura: "Aura",
  Metropolitan: "Metropolitan",
  "Walnut Luster": "Walnut Luster",
  "Artisan Grey": "Artisan Grey",
  "Uptown Grey": "Uptown Grey",
  Leaden: "Leaden",
  Blackburn: "Blackburn",
  Storm: "Storm",
  Embrace: "Embrace",
  Aramis: "Aramis",
  "Black Coral": "Black Coral",
  Silhouette: "Silhouette",
  "Shangri-la": "Shangri-la",
};

export const caesarstoneProducts = {
  "5115 Calacatta Stillstorm": "5115 Calacatta Stillstorm",
  "5116 Calacatta Nectar": "5116 Calacatta Nectar",
  "5118 Calacatta Scoria": "5118 Calacatta Scoria",
  "7524 Golden Sky": "7524 Golden Sky",
  "5144 Rossa Nova": "5144 Rossa Nova",
  "5152 Goldfinch": "5152 Goldfinch",
  "5132 Celestial Sky": "5132 Celestial Sky",
  "5122 Aterra Verity": "5122 Aterra Verity",
  "1111 Vivid White": "1111 Vivid White",
  "1141 Pure White": "1141 Pure White",
  "2141 Blizzard": "2141 Blizzard",
  "9141 Ice Snow": "9141 Ice Snow",
  "4600 Organic White": "4600 Organic White",
  "5121 Layalite": "5121 Layalite",
  "5141 Frosty Carrina": "5141 Frosty Carrina",
  "3141 Eggshell": "3141 Eggshell",
  "5112 Aterra Blanca": "5112 Aterra Blanca",
  "6141 Ocean Foam": "6141 Ocean Foam",
  "6600 Nougat": "6600 Nougat",
  "515 Palm Shade": "515 Palm Shade",
  "405 Midday": "405 Midday",
  "5131 Calacatta Nuvo": "5131 Calacatta Nuvo",
  "5151 Empira White": "5151 Empira White",
  "5031 Statuario Maximus": "5031 Statuario Maximus",
  "5171 Arabetto": "5171 Arabetto",
  "5212 Taj Royale": "5212 Taj Royale",
  "4141 Misty Carrera": "4141 Misty Carrera",
  "5000 London Grey": "5000 London Grey",
  "5110 Alpine Mist": "5110 Alpine Mist",
  "5114 Calacatta Maximus": "5114 Calacatta Maximus",
  "4001 Fresh Concrete": "4001 Fresh Concrete",
  "5111 Statuario Nuvo": "5111 Statuario Nuvo",
  "4601 Frozen Terra": "4601 Frozen Terra",
  "5143 White Attica": "5143 White Attica",
  "5043 Montblanc": "5043 Montblanc",
  "6046 Moorland Fog": "6046 Moorland Fog",
  "6611 Himalayan Moon": "6611 Himalayan Moon",
  "4023 Topus Concrete": "4023 Topus Concrete",
  "4043 Primordia": "4043 Primordia",
  "6131 Bianco Drift": "6131 Bianco Drift",
  "406 Clearskies": "406 Clearskies",
  "6003 Coastal Grey": "6003 Coastal Grey",
  "6313 Turbine Grey": "6313 Turbine Grey",
  "4004 Raw Concrete": "4004 Raw Concrete",
  "4030 Pebble": "4030 Pebble",
  "4003 Sleek Concrete": "4003 Sleek Concrete",
  "2003 Concrete": "2003 Concrete",
  "4120 Raven": "4120 Raven",
  "5810 Black Tempal": "5810 Black Tempal",
  "5820 Darcrest": "5820 Darcrest",
  "3100 Jet Black": "3100 Jet Black",
  "5101 Empira Black": "5101 Empira Black",
};

export const cambriaProducts = {
  Annaleigh: "Annaleigh",
  MonTaaj: "MonTaaj",
  MacBeth: "MacBeth",
  "Inverness Everleigh": "Inverness Everleigh",
  Mayfair: "Mayfair",
  "Windsor Brass Satin Ridge": "Windsor Brass Satin Ridge",
  Templeton: "Templeton",
  Portrush: "Portrush",
  "Mammoth Cave": "Mammoth Cave",
  Ironsbridge: "Ironsbridge",
  Colton: "Colton",
  "Inverness Bristol Bay": "Inverness Bristol Bay",
  Lakedale: "Lakedale",
  Montgomery: "Montgomery",
  "Brittanicca Gold Warm": "Brittanicca Gold Warm",
  Hermitage: "Hermitage",
  Harlow: "Harlow",
  Summerhill: "Summerhill",
  Berwyn: "Berwyn",
  Whitendale: "Whitendale",
  Travella: "Travella",
  Delamere: "Delamere",
  Ella: "Ella",
  Smithfield: "Smithfield",
  Clovelly: "Clovelly",
  Sandgate: "Sandgate",
  Everleigh: "Everleigh",
  "Inverness Frost": "Inverness Frost",
  "Rose Bay": "Rose Bay",
  "Big Sur Mist": "Big Sur Mist",
  "White Cliff": "White Cliff",
  Whitehall: "Whitehall",
  "Salt Lake": "Salt Lake",
  "Minnesota Snow": "Minnesota Snow",
  "Foggy City": "Foggy City",
  Newport: "Newport",
  Dovestone: "Dovestone",
  Delgatie: "Delgatie",
  Seacourt: "Seacourt",
  Torquay: "Torquay",
  "Snowdon White": "Snowdon White",
  Weybourne: "Weybourne",
  Swanbridge: "Swanbridge",
  Malvern: "Malvern",
  Risegate: "Risegate",
  Highgate: "Highgate",
  Ridgegate: "Ridgegate",
  Beckington: "Beckington",
  Southport: "Southport",
  Brittanicca: "Brittanicca",
  "Brittanicca Block": "Brittanicca Block",
  Gladstone: "Gladstone",
  Harrogate: "Harrogate",
  Summerbrook: "Summerbrook",
  Nadenbrook: "Nadenbrook",
  "Brittanicca Warm": "Brittanicca Warm",
  "Inverness Swansea": "Inverness Swansea",
  "Brittanicca Gold Cool": "Brittanicca Gold Cool",
  Hailey: "Hailey",
  Annicca: "Annicca",
  "Skara Brae": "Skara Brae",
  Haydon: "Haydon",
  Abbey: "Abbey",
  Hawksmoore: "Hawksmoore",
  Ruxley: "Ruxley",
  Mackworth: "Mackworth",
  Falconwood: "Falconwood",
  Sutherland: "Sutherland",
  Archdale: "Archdale",
  "Notting Hill": "Notting Hill",
  "Berkshire Brass Satin Ridge": "Berkshire Brass Satin Ridge",
  "Berkshire Brass": "Berkshire Brass",
  "Berkshire Brass Sculpted": "Berkshire Brass Sculpted",
  "Windsor Brass": "Windsor Brass",
  "Windsor Steel Satin Ridge": "Windsor Steel Satin Ridge",
  "Berkshire Steel Sculpted": "Berkshire Steel Sculpted",
  "Inverness Bronze": "Inverness Bronze",
  "Inverness Gold": "Inverness Gold",
  "Inverness Cobalt": "Inverness Cobalt",
  "Inverness Platinum": "Inverness Platinum",
  Hemsworth: "Hemsworth",
  Oakleigh: "Oakleigh",
  Ivybridge: "Ivybridge",
  Bentley: "Bentley",
  Whitney: "Whitney",
  Ainsley: "Ainsley",
  Warwick: "Warwick",
  "Pendle Hill": "Pendle Hill",
  Seagrove: "Seagrove",
  Chatsworth: "Chatsworth",
  Crowndale: "Crowndale",
  Beaumont: "Beaumont",
  Trafalgar: "Trafalgar",
  Clifton: "Clifton",
  Kendal: "Kendal",
  Kelvingrove: "Kelvingrove",
  "Praa Sands": "Praa Sands",
  Bellingham: "Bellingham",
  Axbridge: "Axbridge",
  Huntley: "Huntley",
  Sherwood: "Sherwood",
  "New Quay": "New Quay",
  Galloway: "Galloway",
  Bridport: "Bridport",
  Bradwell: "Bradwell",
  Windrush: "Windrush",
  Dunmore: "Dunmore",
  Greystone: "Greystone",
  "Queen Anne": "Queen Anne",
  Carrick: "Carrick",
  Darlington: "Darlington",
  Baybridge: "Baybridge",
  Windermere: "Windermere",
  Hawkridge: "Hawkridge",
  Copeland: "Copeland",
  "Cardiff Cream": "Cardiff Cream",
  Fairbourne: "Fairbourne",
  Kentmere: "Kentmere",
  "Hyde Park": "Hyde Park",
  Clyde: "Clyde",
  "Burton Brown": "Burton Brown",
  Lancaster: "Lancaster",
  Berkeley: "Berkeley",
  Linwood: "Linwood",
  Halewood: "Halewood",
  Nevern: "Nevern",
  Bradshaw: "Bradshaw",
  Lincolnshire: "Lincolnshire",
  Newhaven: "Newhaven",
  Canterbury: "Canterbury",
  "Talbot Gray": "Talbot Gray",
  Minera: "Minera",
  Brighstone: "Brighstone",
  Brookvale: "Brookvale",
  Devon: "Devon",
  Helmsley: "Helmsley",
  Havergate: "Havergate",
  Harlech: "Harlech",
  Langdon: "Langdon",
  Marwell: "Marwell",
  Laneshaw: "Laneshaw",
  "Park Gate": "Park Gate",
  "Carmarthen Brown": "Carmarthen Brown",
  "Wilshire Red": "Wilshire Red",
  Fieldstone: "Fieldstone",
  Hadley: "Hadley",
  "Ashbourne Matte": "Ashbourne Matte",
  Ellesmere: "Ellesmere",
  Armitage: "Armitage",
  Hamilton: "Hamilton",
  Charlestown: "Charlestown",
  Charston: "Charston",
  "Golden Dragon": "Golden Dragon",
  Woodcroft: "Woodcroft",
  "Black Rock": "Black Rock",
  Mersey: "Mersey",
  Blackbrook: "Blackbrook",
  Menai: "Menai",
  "Cambria Black": "Cambria Black",
  "Blackpool Matte": "Blackpool Matte",
};

export const silestoneProducts = {
  "Stellar Night": "Stellar Night",
  "Gris Expo": "Gris Expo",
  Colors: "Colors",
  Yukon: "Yukon",
  "Blanco City": "Blanco City",
  "Alpina White 08": "Alpina White 08",
  Marengo: "Marengo",
  Lagoon: "Lagoon",
  Lyra: "Lyra",
  "Sienna Ridge 12": "Sienna Ridge 12",
  Helix: "Helix",
  "Stellar Blanco13": "Stellar Blanco13",
  Rougui: "Rougui",
  "White Storm14": "White Storm14",
  "Blanco Norte14": "Blanco Norte14",
  Colors: "Colors",
  "Blanco Orion": "Blanco Orion",
  Calypso: "Calypso",
  "Coral Clay Colour": "Coral Clay Colour",
  Ariel: "Ariel",
  "Copper Mist": "Copper Mist",
  Lusso: "Lusso",
  "Snowy Ibiza": "Snowy Ibiza",
  "Ocean Jasper": "Ocean Jasper",
  "Et Statuario": "Et Statuario",
  "Et Calacatta Gold": "Et Calacatta Gold",
  "Et Marquina": "Et Marquina",
  "Charcoal Soapstone": "Charcoal Soapstone",
  "Miami White17": "Miami White17",
  "Classic Calacatta": "Classic Calacatta",
  "Et Marfil": "Et Marfil",
  "Pearl Jasmine": "Pearl Jasmine",
  "Bianco Calacatta": "Bianco Calacatta",
  Pietra: "Pietra",
  "Night Tebas18": "Night Tebas18",
  "Desert Silver": "Desert Silver",
  "Ocean Storm": "Ocean Storm",
  "Et Noir": "Et Noir",
  "Et Bella": "Et Bella",
  Nolita: "Nolita",
  Camden: "Camden",
  Poblenou: "Poblenou",
  "Et Dor": "Et Dor",
  Seaport: "Seaport",
  Corktown: "Corktown",
  "Miami Vena": "Miami Vena",
  Halcyon: "Halcyon",
  "Cincel Grey": "Cincel Grey",
  "Posidonia Green": "Posidonia Green",
  "Faro White": "Faro White",
  "Cala Blue": "Cala Blue",
  "Arcilla Red": "Arcilla Red",
  "Toscana Cream": "Toscana Cream",
  "White Arabesque": "White Arabesque",
  "Ethereal Dusk": "Ethereal Dusk",
  "Ethereal Haze": "Ethereal Haze",
  "Ethereal Noctis": "Ethereal Noctis",
  "Ethereal Glow": "Ethereal Glow",
  "Ethereal Indigo": "Ethereal Indigo",
  "Ethereal Cloud": "Ethereal Cloud",
  "Lime Delight": "Lime Delight",
  "Concrete Pulse": "Concrete Pulse",
  "Cinder Craze": "Cinder Craze",
  "Brass Relish": "Brass Relish",
  "Romantic Ash": "Romantic Ash",
  "Parisien Bleu": "Parisien Bleu",
  "Victorian Silver": "Victorian Silver",
  "Versailles Ivory": "Versailles Ivory",
  "Eclectic Pearl": "Eclectic Pearl",
  "Bohemian Flame": "Bohemian Flame",
  "Miami Oro": "Miami Oro",
  Ffrom01: "Ffrom01",
  "Raw D": "Raw D",
  Ffrom02: "Ffrom02",
  Ffrom03: "Ffrom03",
  "Raw A": "Raw A",
  "Raw G": "Raw G",
};

export const dektonProducts = {
  Sirius: "Sirius",
  Zenith: "Zenith",
  Domoos: "Domoos",
  Danae: "Danae",
  Aura: "Aura",
  Kairos: "Kairos",
  Entzo: "Entzo",
  Kelya: "Kelya",
  Keon: "Keon",
  Trilium: "Trilium",
  Halo: "Halo",
  Nilium: "Nilium",
  Natura: "Natura",
  Fossil: "Fossil",
  Opera: "Opera",
  Arga: "Arga",
  Kreta: "Kreta",
  Laos: "Laos",
  Soke: "Soke",
  Kira: "Kira",
  Lunar: "Lunar",
  Bergen: "Bergen",
  Bromo: "Bromo",
  Sasea: "Sasea",
  Khalo: "Khalo",
  Moone: "Moone",
  Helena: "Helena",
  Rem: "Rem",
  Aeris: "Aeris",
  Laurent: "Laurent",
  Eter: "Eter",
  Uyuni: "Uyuni",
  Kovik: "Kovik",
  Taga: "Taga",
  Umber: "Umber",
  Micron: "Micron",
  Daze: "Daze",
  Neural: "Neural",
  Vigil: "Vigil",
  Somnia: "Somnia",
  Nacre: "Nacre",
  Albarium: "Albarium",
  Argentium: "Argentium",
  Malibu: "Malibu",
  Laguna: "Laguna",
  Salina: "Salina",
  Marina: "Marina",
  Avorio: "Avorio",
  Dunna: "Dunna",
  Trance: "Trance",
  "Vk04 Grafite": "Vk04 Grafite",
  "Gk07 Ceppo": "Gk07 Ceppo",
  Morpheus: "Morpheus",
  Limbo: "Limbo",
  Somnialm3: "Somnialm3",
  "Tk06 Marmorio": "Tk06 Marmorio",
  Lucid: "Lucid",
  "Vk03 Grigio": "Vk03 Grigio",
  Somnialm2: "Somnialm2",
  Somnialm1: "Somnialm1",
  "Vk01 Nebbia": "Vk01 Nebbia",
  "Vk02 Avorio": "Vk02 Avorio",
  Awake: "Awake",
  "Tk05 Sabbia": "Tk05 Sabbia",
};

export const getBrandProducts = (brand) => {
  const products =
    brand === "Hanstone"
      ? hanstoneProduct
      : brand === "Caesarstone"
      ? caesarstoneProducts
      : brand === "Cambria"
      ? cambriaProducts
      : brand === "Silestone"
      ? silestoneProducts
      : brand === "Dekton"
      ? dektonProducts
      : {};

  const productLists = Object.values(products)?.map((list) => ({
    label: list,
    value: list,
  }));

  return productLists;
};

export const getBrandLists = (category) => {
  const splitted = category?.split(" ")?.[0];
  if (splitted === "Quartz") {
    return quartzBrands;
  } else if (splitted === "Porcelain") {
    return brandOptions;
  } else {
    return brandOptions;
  }
};

export const base64ToFile = async (image, name, type) => {
  const byteCharacters = atob(
    image.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
  );
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type });
  return new File([blob], name, { type });
};

export const accessFeatures = [
  {
    label: "Master Admin",
    value: "fullAccess",
  },
  {
    label: "Home",
    value: "home",
  },
  {
    label: "Media",
    value: "media",
  },
  {
    label: "Articles",
    value: "articles",
  },
  {
    label: "Products",
    value: "products",
  },
  {
    label: "Categories",
    value: "categories",
  },
  {
    label: "Orders",
    value: "orders",
  },
  {
    label: "Withdrawal",
    value: "withdrawal",
  },
  {
    label: "Coupons",
    value: "coupons",
  },
  {
    label: "Store Vendor",
    value: "storevendor",
  },
  {
    label: "Customers",
    value: "customers",
  },
  {
    label: "User With Roles",
    value: "userswithrole",
  },
  {
    label: "Refund",
    value: "refund",
  },
  {
    label: "Notifications",
    value: "notifications",
  },
  {
    label: "Trash",
    value: "trash",
  },
  {
    label: "Feedback",
    value: "feedback",
  },
  {
    label: "Footer Menu",
    value: "footerMenu",
  },
  {
    label: "Setting",
    value: "setting",
  },
  {
    label: "Reports",
    value: "reports",
  },
  {
    label: "Customer Review",
    value: "customerReview",
  },
  {
    label: "Review",
    value: "review",
  },
];

export const couponType = [
  { value: "percentage", label: "Percentage %" },
  { value: "fixed", label: "Fixed Amount $" },
];

export const couponUserType = [
  { value: "newUser", label: "New User" },
  { value: "registeredUser", label: "Registered User" },
];

export const cloudinaryImage = ({ url, width, format, thumbnail }) => {
  let updatedUrl = url
    ?.replace(
      "/upload/",
      `/upload/${width ? `w_${width}/` : ""}${format ? "f_auto/" : ""}`
    )
    ?.replace(".pdf", "");

  if (thumbnail) {
    updatedUrl = updatedUrl
      ?.replace(".mp4", ".jpg")
      .replace(".mov", ".jpg")
      .replace(".webm", ".jpg");
  }

  return updatedUrl;
};

export const extractAddress = (data) => {
  let address = {
    line1: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
  };

  address.line1 = data.structured_formatting.main_text;

  const secondaryParts =
    data.structured_formatting?.secondary_text?.split(", ");

  const isPostalCode = (str) => /^\d{5}(-\d{4})?$/.test(str); // US format, adjust as needed

  if (secondaryParts?.length >= 3) {
    address.city = secondaryParts[0];

    if (isPostalCode(secondaryParts[1])) {
      address.postal_code = secondaryParts[1];
      address.state = secondaryParts[2];
    } else {
      address.state = secondaryParts[1];
      address.postal_code = isPostalCode(secondaryParts[2])
        ? secondaryParts[2]
        : "";
    }

    address.country = secondaryParts[secondaryParts?.length - 1];
  } else if (secondaryParts?.length === 2) {
    const cityStateParts = secondaryParts[0]?.split(" ");
    if (
      cityStateParts.length > 1 &&
      cityStateParts[cityStateParts.length - 1].length === 2
    ) {
      address.city = cityStateParts.slice(0, -1).join(" ");
      address.state = cityStateParts[cityStateParts.length - 1];
    } else {
      address.city = secondaryParts[0];
    }
    address.country = secondaryParts[1];
  } else if (secondaryParts?.length === 1) {
    address.country = secondaryParts[0];
  }

  return address;
};

export const getDistanceFromLatLonInMiles = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8;
  const toRad = (value) => (value * Math.PI) / 180;

  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const prices = (product, option, sectionName, user) => {
  if (sectionName == "Pickup / Delivery") {
    const owner = product?.owner ?? {};
    const vendorLat =
      product?.secondaryLocation?.location?.coordinates?.[1] ||
      owner?.location?.coordinates?.[1] ||
      0;
    const vendorLon =
      product?.secondaryLocation?.location?.coordinates?.[0] ||
      owner?.location?.coordinates?.[0] ||
      0;
    const userLat = user?.location?.coordinates?.[1] || 0;
    const userLon = user?.location?.coordinates?.[0] || 0;
    const distance = getDistanceFromLatLonInMiles(
      vendorLat,
      vendorLon,
      userLat,
      userLon
    );
    if (option?.price != 0) {
      return (
        (distance - product?.freeDeliveryDistance) *
        (option?.price == "TBD" ? 0 : option?.price || 0)
      ).toFixed(2);
    } else {
      return 0;
    }
  }

  if (sectionName == "Fabrication") {
    if (option?.price != 0) {
      return (
        ((product?.length * product?.width) / 144) *
        (option?.price == "TBD" ? 0 : option?.price || 0)
      ).toFixed(2);
    } else {
      return 0;
    }
  }

  if (sectionName == "Installation") {
    if (option?.price != 0) {
      const calculatedPrice =
        ((product?.length * product?.width) / 144) *
        (option?.price == "TBD" ? 0 : option?.price || 0);

      return calculatedPrice % 1 === 0
        ? Math.floor(calculatedPrice)
        : calculatedPrice.toFixed(2);
    } else {
      return 0;
    }
  }

  return option?.price == "TBD" ? 0 : option?.price || 0;
};

export const getDeliveryDate = (day) => {
  let validDays = 0;
  let current = moment().add(1, "day");
  while (validDays < day) {
    current.add(1, "days");
    if (current.day() !== 0) {
      validDays++;
    }
  }
  return current.format("MMM DD YYYY");
};

export const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0;
};

export const isArray = (arr) => {
  return Array.isArray(arr);
};

export const businessTypeOptions = [
  { label: "Individual", value: "individual" },
  { label: "Company", value: "company" },
];

export const scrollTo = (element, offset = 0) => {
  const ele = document.getElementById(element);
  if (ele) {
    const topPosition =
      ele.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  }
};

export const totalAddons = ({ addons, product, user }) => {
  return addons?.reduce((acc, item) => {
    if (item?.pickupChoice && item?.pickupChoice?.price !== "TBD") {
      if (
        item?.pickupChoice?.mode === "Normal Delivery" ||
        item?.pickupChoice?.mode === "Express Delivery"
      ) {
        acc += Number(item?.pickupChoicePrice);
      } else {
        acc += Number(
          prices(product, item?.pickupChoice, "Pickup / Delivery", user)
        );
      }
    }
    if (item?.fabricationChoice && item?.fabricationChoice?.price) {
      acc += Number(
        prices(product, item?.fabricationChoice, "Fabrication", user)
      );
    }
    if (item?.installationChoice && item?.installationChoice?.price) {
      acc += Number(
        prices(product, item?.installationChoice, "Installation", user)
      );
    }
    if (item?.edgeChoice && item?.edgeChoice?.price) {
      acc += item?.edgeChoice?.price;
    }
    if (item?.cutoutChoice && item?.cutoutChoice?.length > 0) {
      acc += item?.cutoutChoice?.reduce(
        (acc, item) => acc + item?.price * item?.quantity,
        0
      );
    }
    if (item?.addedServices && item?.addedServices?.length > 0) {
      acc += item?.addedServices?.reduce((acc, item) => acc + item?.price, 0);
    }
    return acc;
  }, 0);
};

export const feeCalculator = ({ config, total, addonTotal }) => {
  const subtotal = total + (addonTotal || 0);

  const fees = {
    marketingFee: (subtotal * config?.marketingFee) / 100,
    maintenanceFee: (subtotal * config?.maintenanceFee) / 100,
    technologyFee: (subtotal * config?.technologyFee) / 100,
    workerFund: Number(((subtotal * config?.workerFund) / 100).toFixed(2)),
    charityFund: Number(((subtotal * config?.charityFund) / 100).toFixed(2)),
  };

  const price =
    subtotal - Object.values(fees).reduce((sum, fee) => sum + fee, 0);

  return {
    ...fees,
    totalFees: price % 1 === 0 ? Math.floor(price) : price.toFixed(2),
  };
};
