import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import { debounce } from "lodash";
import { TiImage } from "react-icons/ti";
import { connect, useDispatch, useSelector } from "react-redux";
import { NativeSelect } from "@material-ui/core";

import SearchField from "../../../components/SearchFields";
import DialogModal from "../../../components/Modal/DialogModal";
import Header from "../../../components/Headers/DefaultHeader";
import BootstrapInput from "../../../components/UI/BootstrapInput";
import SelectorField from "../../../components/SearchFields/Selector";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import * as Const from "../../../utilities";
import "../../Product/Products.scss";
import Paginator from "../../Pagination";
import ProductList from "../../Product/ProductList";
import Loading from "../../../utilities/loading";
import ProductModal from "../../../components/Modal/ProductModal";
import * as categoryActions from "../../../store/actions/categoryActions";
import * as productActions from "../../../store/actions/productActions";
import { useHistory } from "../../../contexts/AppProvider";

const Product = ({ vendorData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ page: 1, ownerId: vendorData?._id });
  const [order, setOrder] = useState("");
  const [note, setNote] = useState(null);
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [productId, setProductId] = useState("");
  const [flagDialog, setFlagDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { vendorProducts, totalVendorProduct, loading } = useSelector(
    (state) => state.product
  );
  const { categories } = useSelector((state) => state.category);

  const searchParams = new URLSearchParams(history.location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    if (token)
      dispatch(categoryActions.getCategories({ deleted: false }, token));
  }, []);

  useEffect(() => {
    if (!Const.isEmpty(query)) {
      dispatch(
        productActions.getVendorProducts({
          token,
          query: {
            deleted: false,
            ...query,
          },
        })
      );
    }
  }, [query, vendorData?._id]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setQuery({ ...query, page: 1, searchText: value });
    }, 500),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setProductId(id);
    }, 1000);
  }, [id]);

  const onPageChange = (page) => {
    setQuery({ ...query, page });
  };

  const onOpen = (product) => {
    setOpen(true);
    setSelectedProduct(product);
  };

  const onClose = () => {
    setOpen(false);
    setDeleteDialog(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = query?.orderBy === property && query?.order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setQuery({
      ...query,
      orderBy: property,
      order: isAsc ? "desc" : "asc",
      page: 1,
    });
  };

  const handleAddFlag = (product) => {
    if (product) {
      dispatch(
        productActions.addFlag(
          product?._id,
          { flag: !product?.flag, note },
          token
        )
      );
    } else {
      dispatch(
        productActions.addFlag(
          selectedProduct?._id,
          { flag: !selectedProduct?.flag, note },
          token
        )
      );
    }
    setFlagDialog(false);
  };

  const handleDelete = (selectedProduct) => {
    setSelectedProduct(selectedProduct);
    setDeleteDialog(true);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirmDelete = () => {
    dispatch(
      productActions.trashProduct(selectedProduct._id, { deleted: true }, token)
    );
    handleClose();
  };

  const handleAddProduct = () => {
    history.push("/dashboard/add-product");
  };

  const onSearch = (value) => {
    debouncedSearch(value);
  };

  const categorySelect = (value) => {
    setQuery({ ...query, category: value });
  };

  const colorSelect = (value) => {
    setQuery({ ...query, color: value });
  };

  const filterByStatus = (value) => {
    setQuery({ ...query, status: value });
  };

  const filterByLocation = (value) => {
    setQuery({ ...query, secondaryLocation: value });
  };

  const handleEdit = (productId) => {
    history.push("/dashboard/add-product", productId);
  };

  const onSort = (order, orderBy) => {
    setQuery({ ...query, orderBy, order: order === "desc" ? "asc" : "desc" });
  };

  const changeStatus = (data, status) => {
    productActions.changeStatus(data._id, status, token);
  };

  const headCells = [
    { label: "Name", width: 80 },
    { label: "Description", width: 120 },
    { label: "SKU", width: 80 },
    { label: "", width: 60 },
    { label: `Flag an \n inappropriate`, width: 130 },
    { label: "Note" },
    { label: "Status", width: 60 },
    { label: `Total \n Stock`, width: 80 },
    { label: `Available \n Stock`, width: 90 },
    { label: "Price", width: 100 },
    { label: "Taxonomies", width: 150 },
    { id: "views", numeric: true, label: "Views", width: 100 },
    { id: "createdAt", numeric: false, label: "Created Date", width: 200 },
    { label: "Store", width: 100 },
    { label: "Category Meta Keywords", width: 240 },
    { label: "Category Meta Description", width: 240 },
  ];

  const productLocations = (vendorData?.secondaryLocations || [])?.map(
    (location) => ({
      label: `${location?.storeName} - ${location.storeAddress}`,
      value: location?._id,
    })
  );

  return (
    <>
      <Card className="shadow mt-3">
        <CardHeader className="border-0">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="mb-0">Products</h3>
            <div className="counter">{totalVendorProduct}</div>
          </div>
          <div className="d-flex gap-8 flex-wrap justify-content-end">
            <SearchField onChange={onSearch} />
            <SelectorField
              width={180}
              options={categories?.map((cat) => ({
                label: cat?.name,
                value: cat?._id,
              }))}
              placeholder="Select Category"
              onChange={categorySelect}
            />
            <SelectorField
              width={130}
              options={Const.colors}
              placeholder="Select Color"
              onChange={colorSelect}
            />
            <SelectorField
              width={130}
              options={Const.productStatusOptions}
              placeholder="Select Status"
              onChange={filterByStatus}
            />
            <SelectorField
              width={200}
              options={productLocations}
              placeholder="Select Poduct Location"
              onChange={filterByLocation}
            />
            <Button
              onClick={handleAddProduct}
              type="button"
              className="px-3 bg-primary text-white"
            >
              Add Product
            </Button>
          </div>
        </CardHeader>
        <div className="position-relative">
          <Loading
            width={70}
            height={70}
            loading={loading}
            className="kb-loading-table"
          />
          <Table
            className={`${
              loading ? "kb-overlay" : ""
            } "align-items-center mainTable table-flush table table-sm"`}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  <TiImage size={"20px"} />
                </th>
                {headCells.map((cell, index) => (
                  <th scope="col" key={index} style={{ width: cell.width }}>
                    {cell.id ? (
                      <div
                        style={{ fontSize: "14px" }}
                        onClick={() => handleRequestSort(cell.id)}
                        className="d-flex align-items-center cursor-pointer"
                      >
                        {cell.label}{" "}
                        {orderBy === cell.id ? (
                          <>
                            {orderBy === cell.id && order === "asc" && (
                              <i class="fas fa-sort-down ml-1"></i>
                            )}
                            {orderBy === cell.id && order === "desc" && (
                              <i class="fas fa-sort-up ml-1"></i>
                            )}
                          </>
                        ) : (
                          <i class="fas fa-sort ml-1"></i>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {cell.label}
                      </div>
                    )}
                  </th>
                ))}
                <th
                  scope="col"
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    whiteSpace: "pre-line",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <ProductList
              products={vendorProducts}
              handleAddFlag={(selectedProduct) => {
                if (selectedProduct?.flag) {
                  handleAddFlag(selectedProduct);
                } else {
                  setFlagDialog(true);
                  setSelectedProduct(selectedProduct);
                }
              }}
              openModal={onOpen}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              changeStatus={changeStatus}
            />
          </Table>
        </div>

        <CardFooter className="py-4">
          <nav aria-label="...">
            <Paginator
              page={query?.page ?? 1}
              perPage={10}
              onPageChange={onPageChange}
              total={totalVendorProduct}
            />
          </nav>
        </CardFooter>
      </Card>
      <ProductModal
        open={open}
        productId={productId}
        onClose={onClose}
        product={selectedProduct}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      {deleteDialog && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onClose={() => setDeleteDialog(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {flagDialog && (
        <DialogModal
          onClose={() => setFlagDialog(false)}
          onConfirm={handleAddFlag}
          title={"Flag an inappropriate"}
        >
          <textarea
            className="form-control"
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    product: state.product,
    category: state.category,
    loading_: state.product.loading,
    errorMsg: state.product.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (query, token) =>
      dispatch(categoryActions.getCategories(query, token)),
    getProducts: (token, query) =>
      dispatch(productActions.getProducts(token, query)),
    addView: (id, token) => dispatch(productActions.addView(id, token)),
    changeStatus: (id, status, token) =>
      dispatch(productActions.changeStatus(id, status, token)),
    trashProduct: (id, payload, token) =>
      dispatch(productActions.trashProduct(id, payload, token)),
    addFlag: (id, payload, token) =>
      dispatch(productActions.addFlag(id, payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
