import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import ReactSelect from "react-select";
import { TiImage } from "react-icons/ti";
import { Button, Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import ArticleItem from "./ArticleItem";
import Paginator from "../../Pagination";
import SearchField from "../../../components/SearchFields";
import Header from "../../../components/Headers/DefaultHeader";
import Selector from "../../../components/SearchFields/Selector";
import ArticleSkeleton from "../../../components/Skeleton/Article";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import * as articleAction from "../../../store/actions/articleActions";
import * as categoryActions from "../../../store/actions/categoryActions";
import * as Const from "../../../utilities";

const Articles = () => {
  const dispatch = useDispatch();
  const [ids, setIds] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [query, setQuery] = useState({});
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { token, role, user } = useSelector((state) => state.auth);
  const { loading, trashArticles, totalTrashArticle } = useSelector(
    (state) => state.article
  );
  const { categories } = useSelector((state) => state.category);

  const categoryList = categories?.map((category) => ({
    label: category?.name,
    value: category?._id,
  }));

  const skeletons = Array.from({ length: 6 });

  useEffect(() => {
    if (role === "admin") {
      dispatch(articleAction.getArticle({ deleted: true, page: 1 }));
    } else if (token) {
      dispatch(
        articleAction.getArticle({
          memberId: user,
          deleted: true,
          page: 1,
        })
      );
    }

    dispatch(categoryActions.getCategories());
  }, []);

  useEffect(() => {
    if (!Const.isEmpty(query)) {
      dispatch(articleAction.getArticle({ deleted: true, ...query }));
    }
  }, [query]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setQuery({ ...query, page: 1, searchText: value });
    }, 500),
    []
  );

  const filterSelect = (value) => {
    if (value) {
      setQuery({ ...query, status: value, page: 1 });
    } else {
      setQuery({ ...query, status: null, page: 1 });
    }
  };

  const categorySelect = (value) => {
    if (value) {
      setQuery({ ...query, category: value, page: 1 });
    } else {
      setQuery({ ...query, category: null, page: 1 });
    }
  };

  const onSearch = (value) => {
    debouncedSearch(value);
  };

  const onConfirmDelete = () => {
    dispatch(articleAction.deleteArticle(selectedArticle?._id, token, true));
    setDeleteDialog(false);
    setSelectedArticle(null);
  };

  const onConfirmRestore = () => {
    dispatch(
      articleAction.trashArticle(
        selectedArticle?._id,
        { deleted: false },
        token
      )
    );
    setSelectedArticle(null);
    setRestoreDialog(false);
  };

  const onPageChange = (page) => {
    setIds([]);
    setCheckAll(false);
    setQuery({ ...query, page });
  };

  const handleDeleteAll = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to permanently delete these articles?`,
      confirmButtonText: "Yes, I confirm",
      showCancelButton: true,
      cancelButtonColor: "gray",
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: "#007BFF",
    });
    if (isConfirmed) {
      dispatch(articleAction.deleteAllArticles({ ids, token }));
      setIds([]);
      setCheckAll(false);
      setQuery({ ...query, page: 1 });
    }
  };

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setSelectedArticle(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setSelectedArticle(data);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = query?.orderBy === property && query?.order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setQuery({
      ...query,
      orderBy: property,
      order: isAsc ? "desc" : "asc",
      page: 1,
    });
  };

  const headCells = [
    { label: "Name" },
    { label: "Status" },
    { id: "views", numeric: false, label: "Views" },
    { label: "Category" },
    { id: "publishDate", numeric: false, label: "Publish Date" },
    { id: "createdAt", numeric: false, label: "Created Date" },
  ];

  return (
    <>
      <Header headerPadding={true} />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="mb-0">Articles</h3>
                  <div className="counter">{totalTrashArticle}</div>
                </div>
                <div className="d-flex gap-8 flex-wrap justify-content-between">
                  {ids?.length > 0 && (
                    <Button
                      onClick={handleDeleteAll}
                      className="bg-primary text-white px-3"
                    >
                      Delete All
                    </Button>
                  )}
                  <div className="d-flex gap-8 flex-wrap">
                    <SearchField onChange={onSearch} />
                    <Selector
                      width={170}
                      options={Const.articleSelector}
                      onChange={filterSelect}
                      placeholder="Select status"
                    />
                    <Selector
                      width={170}
                      options={categoryList}
                      onChange={categorySelect}
                      placeholder="Select category"
                    />
                  </div>
                </div>
              </CardHeader>
              <div className="position-relative">
                <Table className={"align-items-center table-flush"} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        <Checkbox
                          checked={checkAll}
                          color="primary"
                          onChange={(e) => {
                            setCheckAll(e.target.checked);
                            if (e.target.checked) {
                              setIds(trashArticles.map((ele) => ele._id));
                            } else {
                              setIds([]);
                            }
                          }}
                        />
                      </th>
                      <th scope="col" className="text-center">
                        <TiImage size={"20px"} />
                      </th>
                      {headCells.map((cell) => (
                        <th scope="col">
                          {cell.id ? (
                            <div
                              style={{ fontSize: "14px" }}
                              onClick={() =>
                                handleRequestSort(cell?.sortingId ?? cell.id)
                              }
                              className="d-flex align-items-center cursor-pointer"
                            >
                              {cell.label}{" "}
                              {orderBy === cell.id ||
                              orderBy === cell.sortingId ? (
                                <>
                                  {orderBy === cell.id && order === "asc" && (
                                    <i class="fas fa-sort-down ml-1"></i>
                                  )}
                                  {orderBy === cell.id && order === "desc" && (
                                    <i class="fas fa-sort-up ml-1"></i>
                                  )}
                                </>
                              ) : (
                                <i class="fas fa-sort ml-1"></i>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{ fontSize: "14px" }}
                              className="d-flex align-items-center cursor-pointer"
                            >
                              {cell.label}
                            </div>
                          )}
                        </th>
                      ))}
                      <th style={{ fontSize: "14px" }} scope="col">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      skeletons.map(() => <ArticleSkeleton forTrash={true} />)
                    ) : trashArticles?.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="text-center">
                          Record not found
                        </td>
                      </tr>
                    ) : (
                      trashArticles?.map((article) => (
                        <ArticleItem
                          article={article}
                          handleActions={handleActions}
                          ids={ids}
                          setIds={setIds}
                        />
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    page={query?.page ?? 1}
                    perPage={10}
                    onPageChange={onPageChange}
                    total={totalTrashArticle}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {deleteDialog && (
        <ConfirmationModal
          title={"Are you sure you want to delete?"}
          onClose={() => setDeleteDialog(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {restoreDialog && (
        <ConfirmationModal
          title={"Are you sure you want to restore?"}
          onClose={() => setRestoreDialog(false)}
          onConfirm={onConfirmRestore}
        />
      )}
    </>
  );
};

export default Articles;
