import React, { useMemo } from "react";

const SpecsData = ({ specs }) => {
  const specsImages = useMemo(() => {
    return specs?.productPics?.map((image, i) => {
      return (
        image?.url && (
          <div>
            <div className="d-flex justify-content-center align-items-center overflow-hidden cursor-pointer imageBox">
              <img
                src={image?.url}
                alt={`Product ${i}`}
                width={300}
                height={300}
              />
            </div>
            <p className="mt-2">{image?.description}</p>
          </div>
        )
      );
    });
  }, [specs?.productPics]);

  const specsVideo = useMemo(() => {
    const video = specs?.video;
    return (
      video?.url && (
        <div className="videoBoxWrapper">
          <div className="d-flex justify-content-center align-items-center overflow-hidden cursor-pointer box">
            <video
              style={{
                width: "100%",
                height: "100%",
              }}
              src={video?.url}
              controls
              controlsList="nodownload"
            >
              Your browser does not support the video tag.
            </video>
          </div>
          <p className="mt-2">{video?.description}</p>
        </div>
      )
    );
  }, [specs?.video]);

  return (
    <>
      <div className="mb-4 mt-2">
        <div className="d-flex flex-column w-100">
          <div>How many countertops will you have?</div>
          {specs?.countertops?.map((countertop, index) => (
            <div key={index} className="mt-2">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="font-semibold">
                  #Countertop {countertop?.countertopNo} (Input Final
                  Measurements including any overhang)
                </div>
              </div>
              <div>Length: {countertop?.length}</div>
              <div>Width: {countertop?.width}</div>
              <div>Quantity: {countertop?.quantity}</div>
              <div>Description: {countertop?.description}</div>
            </div>
          ))}
        </div>

        <div className="d-flex flex-column w-100 mt-4">
          <div>How many backsplashes will you have?</div>
          {specs?.backsplash?.map((backsplash, index) => (
            <div key={index} className="mt-2">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="font-semibold">
                  #Backslash {backsplash?.backsplashNo} (Input Final
                  Measurements including any overhang)
                </div>
              </div>
              <div>Length: {backsplash?.length}</div>
              <div>Width: {backsplash?.height}</div>
              <div>Quantity: {backsplash?.quantity}</div>
              <div>Description: {backsplash?.description}</div>
            </div>
          ))}
        </div>
      </div>
      {specs?.productPics?.length > 0 && (
        <React.Fragment>
          <b>Images</b>
          <div className="d-flex flex-wrap w-100 mt-2 mb-4 specsImages">
            {specsImages}
          </div>
        </React.Fragment>
      )}
      {specs?.video && (
        <React.Fragment>
          <div className="d-flex flex-wrap w-100 mt-2 specsVideo">
            <b>Video</b>
            {specsVideo}
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default SpecsData;
